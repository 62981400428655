import { Controller } from "@hotwired/stimulus";
import { ScatterPlot } from "../modules/scatter_plot";
import Swal from "sweetalert2";
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "graph", "form", "alert", "errorMessage", "aveDeltaX", "aveDeltaY", "aveDeltaZ", "aveD",
                     "sigmaD", "avePlusTwoSigma", "duration", "correctPositionX", "correctPositionY", "correctPositionZ",
                     "outputRate", "outputCount", "totalCount", "f", "theta", "sigma", "epsilon", "phi", "rho"];

  declare formTarget: HTMLFormElement;
  declare graphTargets: Array<HTMLElement>;
  declare alertTarget: HTMLElement;
  declare errorMessageTarget: HTMLElement;
  declare aveDeltaXTarget: HTMLElement;
  declare aveDeltaYTarget: HTMLElement;
  declare aveDeltaZTarget: HTMLElement;
  declare aveDTarget: HTMLElement;
  declare sigmaDTarget: HTMLElement;
  declare avePlusTwoSigmaTarget: HTMLElement;
  declare durationTarget: HTMLInputElement;
  declare correctPositionXTarget: HTMLInputElement;
  declare correctPositionYTarget: HTMLInputElement;
  declare correctPositionZTarget: HTMLInputElement;
  declare outputRateTarget: HTMLInputElement;
  declare outputCountTarget: HTMLInputElement;
  declare totalCountTarget: HTMLInputElement;
  declare fTarget: HTMLInputElement;
  declare thetaTarget: HTMLInputElement;
  declare sigmaTarget: HTMLInputElement;
  declare epsilonTarget: HTMLInputElement;
  declare phiTarget: HTMLInputElement;
  declare rhoTarget: HTMLInputElement;

  graphData: { x_y: Array<Array<number>>, x_t: Array<Array<number>>,
               y_t: Array<Array<number>>, z_t: Array<Array<number>>, errors: Array<string> };

  plots: Array<ScatterPlot> = [];

  async draw(event) {

    if (this.plots.length > 0) {
      this.plots.forEach(plot => {
        plot.remove();
      });
      this.plots = [];
    }

    Swal.fire({
      icon: "info",
      title: "通信中",
      timer: 1000,
      width: "16rem",
      position: "top",
      allowEscapeKey: false,
      allowOutsideClick: false
    });

    Swal.showLoading();
    Swal.stopTimer();

    this.graphData = await this.fetchGraphData(event.target.dataset.url);

    if (this.graphData.errors.length > 0) {
      const message = this.graphData.errors.join("\n");
      Swal.update({
        icon: "error",
        title: "エラー",
        text: message,
        allowEscapeKey: true,
        allowOutsideClick: true
      });
    } else {

      this.graphTargets.forEach(elm => {
        const key = elm.dataset.graphKey;
        const dataset = this.graphData[key];

        if (dataset.length > 0) {
          const plot = new ScatterPlot(dataset, elm,
                                       { x: elm.dataset.labelX, y: elm.dataset.labelY },
                                       { x: Number(elm.dataset.ticksX), y: Number(elm.dataset.ticksY) },
                                       key, { x: Number(this.correctPositionXTarget.value),
                                              y: Number(this.correctPositionYTarget.value),
                                              z: Number(this.correctPositionZTarget.value),
                                       }, { xMin: 0, xMax: Number(this.durationTarget.value) });
          plot.draw();
          this.plots.push(plot);
        }
      })

      this.setAnalysisTable(this.graphData);

      Swal.update({
        icon: "success",
        title: "完了",
        allowEscapeKey: true,
        allowOutsideClick: true,
      });
    }

    Swal.resumeTimer();
    Swal.hideLoading();
  }

  alertClose() {
    $(this.alertTarget).hide();
  }

  setAnalysisTable(data) {
    this.aveDeltaXTarget.innerText = data.analysis.ave_delta_x;
    this.aveDeltaYTarget.innerText = data.analysis.ave_delta_y;
    this.aveDeltaZTarget.innerText = data.analysis.ave_delta_z;
    this.aveDTarget.innerText = data.analysis.ave_d;
    this.sigmaDTarget.innerText = data.analysis.sigma_d;
    this.avePlusTwoSigmaTarget.innerText = data.analysis.ave_plus_two_sigma;
    this.outputRateTarget.innerText = (Number(data.analysis.output_rate) * 100).toFixed(0);
    this.outputCountTarget.innerText = data.analysis.output_count;
    this.totalCountTarget.innerText = data.analysis.total_count;
    this.fTarget.innerText = Number(data.analysis.f).toFixed(2);
    this.thetaTarget.innerText = Number(data.analysis.theta).toFixed(2);
    this.sigmaTarget.innerText = Number(data.analysis.sigma).toFixed(2);
    this.epsilonTarget.innerText = Number(data.analysis.epsilon).toFixed(2);
    this.phiTarget.innerText = Number(data.analysis.phi).toFixed(2);
    this.rhoTarget.innerText = (Number(data.analysis.rho) * 100).toFixed(0);
  }

  download(event) {
    this.formTarget.setAttribute("action", event.target.dataset.url);
    this.formTarget.submit();
  }

  async fetchGraphData(url) {
    this.formTarget.setAttribute("action", url);
    const formData = new FormData(this.formTarget);
    const response = await get(url, { query: formData, cache: "no-cache" });

    return response.json;
  }
}
