import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content" ];
  declare contentTarget: HTMLElement

  dumpJsonContent(event) {
    const [data, status, xhr] = event.detail;
    const payload = JSON.stringify(data, null, 2);
    this.contentTarget.innerText = payload;
  }
}
