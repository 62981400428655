import { createGroup, createMarkerMarker } from './create_svg'
import { getMarkerMarkerSize } from "../modules/marker_size";

export const renderMarkerMap = controller => {
  Object.assign(controller, {
    replaceMarkerMap() {
      const markerMap = this.svg.getElementById(this.markerMapId);
      if (markerMap) {
        markerMap.parentNode.removeChild(markerMap);
      }
      this.svg.appendChild(this.createMarkerMap());
    },

    createMarkerMap() {
      const g = createGroup(this.markerMapId);
      for (const marker of this.markerTargets) {
        try {
          const size = getMarkerMarkerSize();
          const { x, y, z, id, name } = marker.dataset;
          const circle = createMarkerMarker(x, y, id, name, "#0d6efd", size);
          const alignX = Math.floor((+x * 1000)) / 1000;
          const alignY = Math.floor((+y * 1000)) / 1000;
          const alignZ = Math.floor((+z * 1000)) / 1000;
          circle.setAttribute("data-marker-size-target", "marker");
          $(circle).tooltip({
            title: markerTooltipTemplate(name, alignX, alignY, alignZ),
            html: true,
            customClass: "asset-position",
          });

          g.appendChild(circle)
        } catch (e) {
          console.log(e);
        }
      }
      return g;
    },

    get markerMapId() {
      return "__markers__";
    }
  })
}

export function markerTooltipTemplate(name, alignX, alignY, alignZ) {
  return (
    [
      `<span class="font-weight-bold border-bottom">${name}</span>`,
      `(${alignX}, ${alignY})`,
    ].join('<br>')
  )
}
