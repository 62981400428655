import { Controller } from "@hotwired/stimulus";
import "vanilla-colorful";
import "vanilla-colorful/hex-input.js";
import { HexInputBase } from "vanilla-colorful/lib/entrypoints/hex-input";

export default class extends Controller {
  static values = { options: Object, defaultColor: String };
  static targets = ["hexInput", "hexColorPicker", "preview"];

  declare optionsValue: Object;
  declare defaultColorValue: string;
  declare hexInputTarget: HexInputBase;
  declare hexColorPickerTarget: HexInputBase;
  declare previewTargets: Array<HTMLElement>;

  connect() {
    this.hexColorPickerTarget.addEventListener('color-changed', (event) => {
      this.hexInputTarget.color = event.detail.value;
      this.previewTargets.forEach((elm) => elm.style.backgroundColor = event.detail.value);
    });

    this.hexInputTarget.addEventListener('color-changed', (event) => {
      this.hexColorPickerTarget.color = event.detail.value;
      this.previewTargets.forEach((elm) => elm.style.backgroundColor = event.detail.value);
    });
  }

  changeDefaultColor() {
    this.hexInputTarget.color = this.defaultColorValue;
    this.hexColorPickerTarget.color = this.defaultColorValue;
      this.previewTargets.forEach((elm) => elm.style.backgroundColor = this.defaultColorValue);
  }
}
