import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja"

export default class extends Controller {
  static values = {
    options: Object
  }

  declare optionsValue: Object;
  fp: any;

  connect() {
    const config = {
      time_24hr: true,
      locale: Japanese,
      ...this.optionsValue
    }
    this.fp = flatpickr(this.element, config);
    this.installHook(this.fp);

    if (this.optionsValue["disableMinute"]) {
      this.disableMinutes();
    }
  }

  installHook(fp) {
    let changed = false
    fp.config.onChange.push(() => { changed = true })
    fp.config.onClose.push(() => {
      if (changed) {
        this.element.dispatchEvent(new CustomEvent("flatpickr:close-with-changed"));
      }
      this.element.dispatchEvent(new CustomEvent("flatpickr:close"));
    })
  }

  disconnect() {
    this.fp.destroy();
  }

  // flatpickrのminutesの入力を無効化
  private disableMinutes() {
    this.fp.minuteElement.disabled = true;
    this.fp.minuteElement.nextElementSibling.classList.add("d-none"); // arrowUp
    this.fp.minuteElement.nextElementSibling.nextElementSibling.classList.add("d-none"); // arrowDown
  }
}
