import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  declare countrowsTarget: HTMLTableElement
  static targets = [ "countrows" ]

  connect() {
    const count = this.rowsCount();
    if (count === 1 ) {
      this.adjust();
      console.log(this.rowsCount());
    } else {
      return
    }
  }
  rowsCount(){
    return this.countrowsTarget.rows.length;
  }
  adjust() {
    const adjust1 = this.countrowsTarget.closest<HTMLElement>(".card-body");
    const adjust2 = this.countrowsTarget.closest<HTMLElement>(".overflow-wrap");
    adjust1.style.paddingTop="0";
    adjust2.style.paddingTop="1.25rem";
  }
}
