import { Controller } from "@hotwired/stimulus"
import { transformPoint } from '../mixins/create_svg'

export default class extends Controller {
  static values = { selector: String };
  static targets = [ "indicator" ];

  declare indicatorTarget: HTMLElement
  declare selectorValue: string

  connect() {
    this.install();
  }

  install() {
    if (this.svg) {
      // TODO: throttle
      $(this.svg).on('mousemove', this.onMousemove);
    }
  }

  onMousemove = (event) => {
    const point = this.transformClientXY(event);
    const text = `x: ${point.x.toFixed(3)}, y: ${point.y.toFixed(3)}`
    this.indicatorTarget.innerText = text
  }

  transformClientXY(event) {
    return transformPoint(event.clientX, event.clientY, this.svg);
  }

  get svg() {
    return this.element.querySelector(this.selectorValue || 'svg');
  }
}
