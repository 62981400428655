import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shareType", "linkForm", "pathForm", "fileForm"]

  connect() {
    this.switchForm()
  }

  switchForm() {
    const typeValue = this.shareTypeTarget.value;

    this.hideAllForms();
    this.setAllFormsRequired(false);

    switch(typeValue) {
        case 'link':
            this.showLinkForm();
            this.setLinkFormRequired(true);
            break;
        case 'path':
            this.showPathForm();
            this.setPathFormRequired(true);
            break;
        case 'download':
            this.showFileForm();
            this.setFileFormRequired(true);
            break;
    }
  }

  hideAllForms() {
      this.hideLinkForm();
      this.hideFileForm();
      this.hidePathForm();
  }

  setAllFormsRequired(value: boolean) {
      this.setLinkFormRequired(value);
      this.setPathFormRequired(value);
      this.setFileFormRequired(value);
  }

  showLinkForm() {
    this.linkFormTarget.style.display = 'block'
  }

  hideLinkForm() {
    this.linkFormTarget.style.display = 'none'
  }

  showPathForm() {
    this.pathFormTarget.style.display = 'block'
  }

  hidePathForm() {
    this.pathFormTarget.style.display = 'none'
  }

  showFileForm() {
    this.fileFormTarget.style.display = 'block'
  }

  hideFileForm() {
    this.fileFormTarget.style.display = 'none'
  }

  setLinkFormRequired(required) {
    const linkField = this.linkFormTarget.querySelector('input[type="text"]')
    linkField.required = required
  }

  setPathFormRequired(required) {
    const pathField = this.pathFormTarget.querySelector('input[type="text"]')
    pathField.required = required
  }

  setFileFormRequired(required) {
    const fileField = this.fileFormTarget.querySelector('input[type="file"]')
    fileField.required = required
  }
}
