import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit(event) {
    const element = (this.element as HTMLFormElement);
    const oldAction = element.getAttribute("action");
    if (event.target.dataset.url) {
      element.setAttribute("action", event.target.dataset.url);
    }
    element.submit();
    element.setAttribute("action", oldAction);
  }

  submitEnter(e: KeyboardEvent) {
    if (e.key == "Enter") {
      this.submit(e);
    }
  }
}
// 対象
// <form data-controller="form">
//   <button data-action="form#submit">Send</button>
// </select>
