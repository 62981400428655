type TrackingStatus = 'tracking' | 'all'

export const DEFAULT_TRACKING_STATUS: TrackingStatus = 'tracking';
export const COOKIE_TRACKING_STATUS_SIZE = "tracking_status";

const trackingStatuses = {
  'tracking': 'tracking',
  'all': 'all',
}
let currentTrackingStatus: TrackingStatus = 'tracking'

export function getTrackingStatus() : TrackingStatus {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('tracking_status')) {
    return trackingStatuses[searchParams.get('tracking_status')] || DEFAULT_TRACKING_STATUS
  }
  return currentTrackingStatus || DEFAULT_TRACKING_STATUS
}

export function setTrackingStatus(tracking_status: TrackingStatus) {
  currentTrackingStatus =  tracking_status;
}

export function applyTrackingStatusStyle(element: HTMLElement | SVGElement, trackingStatus: TrackingStatus) {
  if (trackingStatus == "all") {
    element.classList.remove('d-none');
  }
  if (trackingStatus == "tracking") {
    if (element.dataset.trackingStatus != "tracking") {
      element.classList.add('d-none');
    }
  }
}
