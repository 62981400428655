import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["locatorCheck", "hiddenTemplate", "output"];

  declare locatorCheckTargets: Array<HTMLInputElement>;
  declare hiddenTemplateTarget: HTMLTemplateElement;
  declare outputTarget: HTMLElement;

  connect() {
    this.replaceTargetLocator();
  }

  replaceTargetLocator() {
    const targetLocators = this.checkedLocatorIds().map((locatoId) => {
      const hiddenField = this.hiddenTemplateTarget.content.firstElementChild.cloneNode(true) as HTMLInputElement;
      hiddenField.value = locatoId;
      return hiddenField.outerHTML;
    });
    this.outputTarget.innerHTML = targetLocators.join("\n");
  }

  checkedLocatorIds() {
    return this.locatorCheckTargets.reduce((acc, elm) => {
      if (elm.checked) {
        acc.push(elm.value);
      }
      return acc;
    }, []);
  }
}
