import { Controller } from "@hotwired/stimulus"
import { renderLocatorMap } from "../../mixins/render_locator_map"
import { renderAreaRegion } from "../../mixins/render_area_region"
import { transformPoint, createGroup, createPolygon } from '../../mixins/create_svg'
import { Circle } from "../../modules/svg/circle";

export default class FloorMapController extends Controller {
  static targets = ["locator", "segment", "startX", "startY", "startZ", "endX", "endY", "endZ"];
  static values = { action: String, segment: Object, locatorPositions: Array, areaRegions: Array };

  declare areaRegionsValue: Array<Object>
  declare locatorTargets: Array<HTMLElement>
  declare segmentTargets: Array<HTMLElement>
  declare startXTarget: HTMLInputElement
  declare startYTarget: HTMLInputElement
  declare startZTarget: HTMLInputElement
  declare endXTarget: HTMLInputElement
  declare endYTarget: HTMLInputElement
  declare endZTarget: HTMLInputElement
  declare actionValue: string;
  declare locatorPositionsValue: Array<Object>;
  declare segmentValue: {
    id: number, "start_x": number, "start_y": number, "start_z": number,
    "end_x": number, "end_y": number, "end_z": number, "typical_z": number
  };
  replaceLocatorMap: Function
  segmentGroup: SVGElement
  square: SVGPolygonElement
  startCircle: Circle
  endCircle: Circle
  selectedCircle: Circle
  offsetX: number
  offsetY: number
  replaceAreaRegion: Function

  squareWidth: number = 0.05;

  initialize() {
    renderLocatorMap(this);
    renderAreaRegion(this);
  }

  render() {
    if (this.svg) {
      this.replaceLocatorMap();
      this.replaceAreaRegion();
      this.renderSegmentGroup();
      this.setAction();
    }
  }

  setAction() {
    if (this.actionValue == "new") {
      this.svg.addEventListener("mouseup", event => this.click(event));
      this.svg.addEventListener("mouseup", event => this.endDrag(event));
      this.svg.addEventListener("mousemove", event => this.drag(event));
    } else if (this.actionValue == "edit") {
      this.svg.addEventListener("mouseup", event => this.endDrag(event));
      this.svg.addEventListener("mousemove", event => this.drag(event));
    }
  }

  clear() {
    if (this.actionValue == "edit") {
      this.startXTarget.value = String(this.segmentValue.start_x);
      this.startYTarget.value = String(this.segmentValue.start_y);
      this.endXTarget.value = String(this.segmentValue.end_x);
      this.endYTarget.value = String(this.segmentValue.end_y);
      this.syncCircle();
    } else {
      if (this.startCircle) {
        this.startCircle.circle.remove();
        this.startCircle = null;
      }
      if (this.endCircle) {
        this.endCircle.circle.remove();
        this.endCircle = null;
      }
      if (this.square) {
        this.square.remove();
        this.square = null;
      }

      this.startXTarget.value = "0";
      this.startYTarget.value = "0";
      this.endXTarget.value = "0";
      this.endYTarget.value = "0";
    }
  }

  transformClientXY(event) {
    const x = Circle.parseFloatFixed(event.clientX);
    const y = Circle.parseFloatFixed(event.clientY);
    return transformPoint(x, y, this.svg);
  }

  click(event) {
    event.preventDefault();
    const point = this.transformClientXY(event);
    if (!this.startCircle) {
      this.setStartCircle(point);
      this.startCircle.circle.addEventListener("mousedown", event => this.startDrag(event));
    } else if (!this.endCircle) {
      const color = "#3d55cc";
      this.setEndCircle(point);
      this.endCircle.circle.addEventListener("mousedown", event => this.startDrag(event));
      this.adjustCircle();
      this.square = createPolygon(this.convertPoints(), color, this.squareWidth, color);
      this.segmentGroup.appendChild(this.square);
    }
  }

  setOffsetXY(point) {
    this.offsetX = point.x;
    this.offsetY = point.y;
  }

  drag(event) {
    if (this.selectedCircle) {
      event.preventDefault();
      const point = this.transformClientXY(event);
      const { x, y } = this.calcDragPosition(point);
      this.selectedCircle.x = x;
      this.selectedCircle.y = y;
      this.syncInputValue();
      this.changeSquare();
      this.setOffsetXY(point);
    }
  }

  startDrag(event) {
    event.preventDefault();
    this.selectedCircle = new Circle(event.target);
    const point = this.transformClientXY(event);
    this.setOffsetXY(point);
  }

  endDrag(event) {
    if (this.selectedCircle) {
      this.adjustCircle();
      this.selectedCircle = null;
    }
  }

  calcDragPosition(point) {
    const x = this.selectedCircle.x + point.x - this.offsetX;
    const y = this.selectedCircle.y + point.y - this.offsetY;
    return { x, y };
  }

  convertPoints() {
    return `
      ${this.points.start.x},${this.points.start.y}
      ${this.points.end.x},${this.points.start.y}
      ${this.points.end.x},${this.points.end.y}
      ${this.points.start.x},${this.points.end.y}
    `
  }

  setStartCircle(point) {
    if (!this.startCircle) {
      this.startCircle = this.createCircle(point, "navy");
      this.startXTarget.value = String(this.points.start.x);
      this.startYTarget.value = String(this.points.start.y);
      this.segmentGroup.appendChild(this.startCircle.circle);
    }
  }

  syncCircle() {
    if (this.startCircle) {
      this.startCircle.x = Number(this.startXTarget.value);
      this.startCircle.y = Number(this.startYTarget.value);
    }

    if (this.endCircle) {
      this.endCircle.x = Number(this.endXTarget.value);
      this.endCircle.y = Number(this.endYTarget.value);
    }

    this.adjustCircle();

    if (this.square) {
      this.square.setAttribute("points", this.convertPoints());
    }
  }

  setEndCircle(point) {
    if (!this.endCircle) {
      this.endCircle = this.createCircle(point, "skyblue");
      this.endXTarget.value = String(this.points.end.x);
      this.endYTarget.value = String(this.points.end.y);
      this.segmentGroup.appendChild(this.endCircle.circle);
    }
  }

  adjustCircle() {
    if (this.startCircle && this.endCircle) {
      const startX = this.startCircle.x;
      const startY = this.startCircle.y;
      const endX = this.endCircle.x;
      const endY = this.endCircle.y;
      if (startX > endX) {
        this.startCircle.x = endX;
        this.endCircle.x = startX;
      }

      if (startY > endY) {
        this.startCircle.y = endY;
        this.endCircle.y = startY;
      }
      this.adjustZ();
      this.syncInputValue();
    }
  }

  adjustZ() {
    const startZ = Number(this.startZTarget.value);
    const endZ = Number(this.endZTarget.value);
    if (startZ > endZ) {
      this.startZTarget.value = String(endZ);
      this.endZTarget.value = String(startZ);
    }
  }

  syncInputValue() {
    this.startXTarget.value = String(this.points.start.x);
    this.startYTarget.value = String(this.points.start.y);
    this.endXTarget.value = String(this.points.end.x);
    this.endYTarget.value = String(this.points.end.y);
  }

  changeSquare() {
    if (this.square) {
      this.square.setAttribute("points", this.convertPoints());
    }
  }

  renderSegmentGroup() {
    this.segmentGroup = createGroup(this.segmentId);
    for (const segment of this.segmentTargets) {
      try {
        const { id, points, startX, startY, endX, endY, locatorCode } = segment.dataset;
        const startColor = Number(id) === this.segmentValue.id ? "#ff33ff" : "navy";
        const startCircle = Circle.create(startX, startY, startColor);
        const endColor = Number(id) === this.segmentValue.id ? "#ffff33" : "skyblue";
        const endCircle = Circle.create(endX, endY, endColor);
        const squareColor = Number(id) === this.segmentValue.id ? "#66cdaa" : "#9166cc";
        const square = createPolygon(points, squareColor, this.squareWidth, squareColor);
        const toastSelector = "#segment-toast-" + id;

        if ($(toastSelector).html()) {
          $(square).tooltip({
            container: 'body',
            title: $(toastSelector).html(),
            html: true,
            customClass: "locator-position",
            sanitize: false,
            trigger: "click focus",
          });
        }

        if (locatorCode) {
          startCircle.circle.style.display = "none";
          endCircle.circle.style.display = "none";
          square.style.display = "none";
          const locatorSquare = $(`#__locators__ > rect[data-code='${locatorCode}']`);
          locatorSquare.on("mouseenter", (e) => {
            startCircle.circle.style.display = "block";
            endCircle.circle.style.display = "block";
            square.style.display = "block";
          });

          locatorSquare.on("mouseleave", (e) => {
            startCircle.circle.style.display = "none";
            endCircle.circle.style.display = "none";
            square.style.display = "none";
          });
        }

        if (this.actionValue === "edit" && Number(id) === this.segmentValue.id) {
          this.editCircle(startCircle, endCircle, square);
        } else {
          square.style.pointerEvents = "none";
          startCircle.circle.style.pointerEvents = "none";
          endCircle.circle.style.pointerEvents = "none";
        }

        this.segmentGroup.appendChild(startCircle.circle);
        this.segmentGroup.appendChild(endCircle.circle);
        this.segmentGroup.appendChild(square);
      } catch (e) {
        console.log(e);
      }
    }
    this.svg.appendChild(this.segmentGroup);
  }

  editCircle(startCircle, endCircle, square) {
    this.startCircle = startCircle;
    this.endCircle = endCircle;
    this.square = square;
    this.startCircle.circle.addEventListener("mousedown", event => this.startDrag(event));
    this.endCircle.circle.addEventListener("mousedown", event => this.startDrag(event));
  }

  createCircle(point, color) {
    const x = Circle.parseFloatFixed(point.x);
    const y = Circle.parseFloatFixed(point.y);
    const circle = Circle.create(x, y, color);
    return circle;
  }

  get points() {
    return {
      start: {
        x: this.startCircle ? this.startCircle.x : null,
        y: this.startCircle ? this.startCircle.y : null,
      },
      end: {
        x: this.endCircle ? this.endCircle.x : null,
        y: this.endCircle ? this.endCircle.y : null
      }
    }
  }

  get svg() {
    return this.element.getElementsByClassName("svg-map")[0].getElementsByTagName("svg")[0];
  }

  get segmentId() {
    return "__segment__";
  }

}
