import { createLocatorMaker } from "../mixins/create_svg";
import { getLocatorMakerSize } from "../modules/marker_size";

export class LocatorPosition {
  rect: SVGRectElement;
  static readonly COLOR: string = "blue";

  static create(x, y, code = null) {
    return new LocatorPosition(createLocatorMaker(x, y, code, this.COLOR, LocatorPosition.size()));
  }

  static size() {
    return getLocatorMakerSize();
  }

  static half_size() {
    return LocatorPosition.size() / 2;
  }

  static parseFloatFixed(num) {
    return Number.parseFloat(num).toFixed(3);
  }

  constructor(rect: SVGRectElement) {
    this.rect = rect;
  }

  get code(): string {
    return this.rect.dataset.code;
  }

  get x(): number {
    return Number(this.rect.getAttribute("x"));
  }

  get y(): number {
    return Number(this.rect.getAttribute("y"));
  }

  set x(value: number) {
    this.rect.setAttribute("x", LocatorPosition.parseFloatFixed(value));
  }

  set y(value: number) {
    this.rect.setAttribute("y", LocatorPosition.parseFloatFixed(value));
  }
}
