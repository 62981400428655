import { Controller } from "@hotwired/stimulus"
import { EventSourcePolyfill } from "event-source-polyfill";

export default class extends Controller {
  static targets = [ "text" ];

  declare textTarget: HTMLInputElement

  copy(event) {
    this.textTarget.select();
    document.execCommand("copy");
  }
}
