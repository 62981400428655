import * as d3 from "d3";

export class ScatterPlot {
  width: number;
  height: number;
  labelName: { x: string, y: string };
  margin = { top: 30, bottom: 60, right: 30, left: 60 };
  dataset: Array<Array<number>>;
  elm: HTMLElement;
  svg: any;
  ticks: { x: number, y: number };
  correctLine?: number;
  graphKey: string;
  correctPosition?: { x: number, y: number, z: number };
  bounds?: { xMin: number, xMax: number, yMin: number, yMax: number };

  constructor(dataset: Array<Array<number>>, elm: HTMLElement, labelName, ticks = null,
              graphKey, correctPosition, bounds, width: number = 400, height: number = 300) {

    this.width = width;
    this.height = height;
    this.dataset = dataset;
    this.elm = elm;
    this.labelName = labelName;
    this.correctPosition = correctPosition;
    this.graphKey = graphKey;
    if (ticks) {
      this.ticks = ticks;
    }

    if (this.graphKey === "x_t") {
      this.correctLine = correctPosition.x;
    } else if (this.graphKey === "y_t") {
      this.correctLine = correctPosition.y;
    } else if (this.graphKey === "z_t") {
      this.correctLine = correctPosition.z;
    }

    this.bounds = {
      xMin: bounds.xMin >= 0 ? bounds.xMin : this.calcMinX(),
      xMax: bounds.xMax >= 0 ? bounds.xMax : this.calcMaxX(),
      yMin: bounds.yMin >= 0 ? bounds.yMin : this.calcMinY(),
      yMax: bounds.yMax >= 0 ? bounds.yMax : this.calcMaxY(),
    };
  }

  draw() {
    this.svg = d3.select(this.elm).append("svg").attr("viewBox", `0 0 ${this.width} ${this.height}`);

    const xScale = d3.scaleLinear()
                     .domain([this.bounds.xMin, this.bounds.xMax])
                     .range([this.margin.left, this.width - this.margin.right]);
    const yScale = d3.scaleLinear()
                     .domain([this.bounds.yMin, this.bounds.yMax])
                     .range([this.height - this.margin.bottom, this.margin.top]);

    const axisx = d3.axisBottom(xScale).ticks(this.ticks.x);
    const axisy = d3.axisLeft(yScale).ticks(this.ticks.y);


    const scalePlotGroup = this.svg.append("g");

    scalePlotGroup
      .selectAll("circle")
      .data(this.dataset)
      .enter()
      .append("circle")
      .attr("cx", d => xScale(d[0]))
      .attr("cy", d => yScale(d[1]))
      .attr("fill", "steelblue")
      .attr("r", 2);

    if (this.graphKey === "x_y") {
      this.drawCorrectArea(scalePlotGroup, xScale, yScale);
    }

    this.drawXLabel(this.svg, axisx);
    this.drawYLabel(this.svg, axisy);
  }

  remove() {
    if (this.svg) {
      this.svg.remove();
    }
  }

  drawCorrectArea(scalePlotGroup, xScale, yScale) {
    const cx = xScale(this.correctPosition.x);
    const cy = yScale(this.correctPosition.y);
    const rx = Math.abs(xScale(this.correctPosition.x + 1) - cx);
    const ry = Math.abs(yScale(this.correctPosition.y + 1) - cy);

    scalePlotGroup.append("circle")
    .attr("cx", cx)
    .attr("cy", cy)
    .attr("fill", "#ff6347")
    .attr("r", 2);

    scalePlotGroup.append("ellipse")
    .attr("cx", cx)
    .attr("cy", cy)
    .attr("rx", rx)
    .attr("ry", ry)
    .attr("fill", "none")
    .attr("stroke", "#ff6347")
    .attr("stroke-width", 2);

  }

  drawXLabel(svg, axisx) {
    svg.append("g")
    .attr("transform", "translate(" + 0 + "," + (this.height - this.margin.bottom) + ")")
    .call(axisx)
    .append("text")
    .attr("fill", "black")
    .attr("x", (this.width - this.margin.left - this.margin.right) / 2 + this.margin.left)
    .attr("y", 35)
    .attr("text-anchor", "middle")
    .attr("font-size", "1rem")
    .attr("font-weight", "bold")
    .text(this.labelName.x);
  }

  drawYLabel(svg, axisy) {
    if (this.graphKey === "x_y") {
      svg.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + 0 + ")")
        .call(axisy)
        .append("text")
        .attr("fill", "black")
        .attr("x", -(this.height - this.margin.top - this.margin.bottom) / 2 - this.margin.top)
        .attr("y", -35)
        .attr("transform", "rotate(-90)")
        .attr("text-anchor", "middle")
        .attr("font-weight", "bold")
        .attr("font-size", "1rem")
        .text(this.labelName.y);
    } else {
      svg.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + 0 + ")")
        .call(axisy)
        .call(g => g.selectAll(".tick line")
             .filter(d => d === 0)
             .clone()
              .attr("y1", this.convertCorrectLine())
              .attr("y2", this.convertCorrectLine())
              .attr("x2", this.width - this.margin.right - this.margin.left)
              .attr("stroke", "#ff6347"))
        .append("text")
        .attr("fill", "black")
        .attr("x", -(this.height - this.margin.top - this.margin.bottom) / 2 - this.margin.top)
        .attr("y", -35)
        .attr("transform", "rotate(-90)")
        .attr("text-anchor", "middle")
        .attr("font-weight", "bold")
        .attr("font-size", "1rem")
        .text(this.labelName.y);
    }
  }

  calcMaxX() {
    const max = d3.max(this.dataset, d => d[0]);

    return Math.ceil((max + this.ticks.x) / this.ticks.x) * this.ticks.x;
  }

  calcMaxY() {
    const max = d3.max(this.dataset, d => d[1])

    if (this.correctLine && this.correctLine > max) {
      return Math.ceil((this.correctLine + this.ticks.y) / this.ticks.y) * this.ticks.y;
    } else {
      return Math.ceil((max + this.ticks.y) / this.ticks.y) * this.ticks.y;
    }
  }

  calcMinX() {
    const min = d3.min(this.dataset, d => d[0]);

    if (min === 0) {
      return min;
    } else {
      return Math.ceil((min - this.ticks.x) / this.ticks.x) * this.ticks.x;
    }
  }

  calcMinY() {
    const min = d3.min(this.dataset, d => d[1]);

    if (this.correctLine && this.correctLine < min) {
      return Math.ceil((this.correctLine - this.ticks.y) / this.ticks.y) * this.ticks.y;
    } else if (min === 0) {
      return min;
    } else {
      return Math.ceil((min - this.ticks.y) / this.ticks.y) * this.ticks.y;
    }
  }

  convertCorrectLine(): number {
    const graphHeight = this.height - this.margin.top - this.margin.bottom;
    const yMax = this.calcMaxY();
    const yMin = this.calcMinY();
    const scaleLength = Math.abs(yMax) + Math.abs(yMin);
    const yScalePercentage = this.correctLine / scaleLength;
    return graphHeight * yScalePercentage * -1;
  }
}
