import { Controller } from "@hotwired/stimulus"
import { createGroup, createElementSvg } from '../../mixins/create_svg'

export default class extends Controller {
  static values = { selector: String, positions: Array }

  declare selectorValue: string
  declare positionsValue: Array<Object>

  readonly color = "#024C97"

  connect() {
    this.install()
  }

  install() {
    if (this.svg) {
      this.drawHeatmap(this.svg)
    }
  }

  private drawHeatmap(svg: any) {
    const g = createGroup("__heatmap__")

    this.positionsValue.forEach(position => {
      const x = position["x"]
      const y = position["y"]
      const opacity = position["o"]
      const count = position["count"]
      const cell = this.createCell(x, y, 1, 1, this.color, opacity, count)

      g.appendChild(cell)
    })

    svg.appendChild(g)
  }

  private createCell(x: number, y: number, width: number, height: number, color: string, opacity: number, count: number): any {
    const marker = createElementSvg("rect")
    marker.setAttribute("x", x)
    marker.setAttribute("y", y)
    marker.setAttribute("width", width)
    marker.setAttribute("height", height)
    marker.setAttribute("fill", color)
    marker.setAttribute("opacity", opacity)
    $(marker).tooltip({
      title: [
        `x: ${x}`,
        `y: ${y}`,
        `count: ${count}`,
      ].join('<br>'),
      html: true,
      customClass: "asset-position",
    })
    return marker
  }

  get svg() {
    return this.element.querySelector(this.selectorValue || 'svg');
  }
}
