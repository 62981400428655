import { Controller } from "@hotwired/stimulus"
import { Japanese } from "flatpickr/dist/l10n/ja"
import { createGroup, createPositionMaker, createLine } from '../../mixins/create_svg'
import flatpickr from "flatpickr"
import * as moment from "moment"
import * as anime from "animejs/lib/anime.js"

export default class extends Controller {
  static targets = ["baseAt", "playIcon", "svgMap", "progress", "bullet", "asset", "assetPosition", "duration"]
  static values = { selector: String, assets: Array, speed: Number, remains: Number, isMultipleFloor: Boolean, isEnableFlatpickrHook: Boolean }
  isLoading: boolean = true
  isPlaying: boolean = false
  flowlineDuration: number = 60  // 動線表示の全体秒数
  animDuration: number = 10      // 動線が描画されてから消えるまでの秒数
  animSpeed: number = 10         // 動線描画の倍速度
  fp: any
  startTime: any
  endTime: any

  declare selectorValue: string
  declare assetsValue: Array<Object>
  declare speedValue: number
  declare remainsValue: number
  declare isMultipleFloorValue: boolean
  declare isEnableFlatpickrHookValue: boolean
  declare baseAtTarget: HTMLInputElement & { _flatpickr: any }
  declare durationTarget: HTMLInputElement
  declare playIconTarget: HTMLImageElement
  declare svgMapTarget: HTMLCanvasElement
  declare progressTarget: HTMLInputElement
  declare bulletTarget: HTMLSpanElement
  declare hasAssetPositionTarget: boolean
  declare assetPositionTargets: Array<HTMLElement>
  declare assetPositions: any
  declare dataset: Array<any>
  declare anim: any

  connect() {
    this.flowlineDuration = Number(this.durationTarget.value)
    this.startTime = moment(this.baseAtTarget.value)
    this.endTime = moment(this.startTime + moment.duration(this.flowlineDuration, "s"))
    this.setAnimSpeedAndDuration()
    this.buildFlatpickr()
    this.buildAnim()
    this.buildDataset()
  }

  disconnect() {
    this.fp.destroy()
  }

  // base_at変更時にクエリパラメータを更新してリロード
  changeBaseAt() {
    const params = new URLSearchParams(window.location.search)
    params.set("base_at", this.baseAtTarget.value)
    window.location.search = params.toString()
  }

  moveBullet() {
    if (this.isLoading || this.isPlaying) return

    this.anim.seek(this.anim.duration * Number(this.progressTarget.value) / 100)
    this.updateBullet()
  }

  play() {
    if (this.isLoading) return

    if (!this.isPlaying) {
      this.isPlaying = true
      this.playIconTarget.classList.remove("fa-play")
      this.playIconTarget.classList.add("fa-pause")
      this.anim.play()
    } else {
      this.isPlaying = false
      this.playIconTarget.classList.remove("fa-pause")
      this.playIconTarget.classList.add("fa-play")
      this.anim.pause()
    }
  }

  install() {
    if (this.dataset.length == 0) return

    if (this.isMultipleFloorValue) {
      if (this.checkAllSvgMap()) {
        this.dataset.forEach((data, i) => {
          const svg = document.getElementsByClassName("floor-svg")[i].getElementsByTagName("svg")[0]
          this.drawDots(data, svg)
          this.drawLines(data, svg)
        })
        this.addDrawAnims([].concat(...this.dataset))
      }
    } else {
      if (this.svg) {
        this.drawDots(this.dataset, this.svg)
        this.drawLines(this.dataset, this.svg)
        this.addDrawAnims(this.dataset)
      }
    }
  }

  private buildFlatpickr() {
    this.fp = flatpickr(this.baseAtTarget, {
      enableTime: true,
      enableSeconds: true,
      time_24hr: true,
      locale: Japanese,
      dateFormat: "Y-m-d H:i:S",
    })
    if (this.isEnableFlatpickrHookValue) {
      this.fp.config.onClose.push(() => {
        this.changeBaseAt()
      })
    }
  }

  private buildAnim() {
    this.anim = anime.timeline({
      direction: "normal",
      autoplay: false,
      update: (anim) => {
        this.progressTarget.value = anim.progress
        this.updateBullet()
        if (anim.progress >= 100) {
          this.isPlaying = false
          this.playIconTarget.classList.remove("fa-pause")
          this.playIconTarget.classList.add("fa-play")
        }
      }
    })
  }

  private updateBullet() {
    this.bulletTarget.style.left = `${Number(this.progressTarget.value) / 100 * 383}px`

    if (this.flowlineDuration == 600) {
      this.bulletTarget.innerHTML = `${Number(this.progressTarget.value) / 10}分`
    } else if (this.flowlineDuration == 60) {
      this.bulletTarget.innerHTML = `${Math.floor(Number(this.progressTarget.value) / 100 * 60)}秒`
    }
  }

  private buildDataset() {
    this.dataset = []
    if (!this.hasAssetPositionTarget) return

    if (this.isMultipleFloorValue) {
      let preFloorId = this.assetPositionTargets[0].dataset.floor_id
      let tmpData = []
      for (const ap of this.assetPositionTargets) {
        const { x, y, z, assetAnyId, managementCode, color, at, floorId } = ap.dataset
        if (preFloorId != floorId) {
          if (tmpData.length != 0) this.dataset.push(tmpData)
          tmpData = []
          preFloorId = floorId
        }
        tmpData.push(this.buildData(x, y, z, assetAnyId, managementCode, color, at))
      }
      this.dataset.push(tmpData)
    } else {
      for (const ap of this.assetPositionTargets) {
        const { x, y, z, assetAnyId, managementCode, color, at } = ap.dataset
        this.dataset.push(this.buildData(x, y, z, assetAnyId, managementCode, color, at))
      }
      // managementCodeでソート
      this.dataset = this.dataset
        .sort((a, b) =>
          (a.managementCode > b.managementCode) ? 1
            : (b.managementCode > a.managementCode) ? -1 : 0)
    }
  }

  private setAnimSpeedAndDuration() {
    if (this.speedValue) {
      if (this.speedValue > 0 && this.speedValue <= 15) {
        this.animSpeed = this.speedValue
      }
    }
    if (this.remainsValue) {
      if (this.remainsValue > 0 && this.remainsValue <= 15) {
        this.animDuration = this.remainsValue
      }
    }
  }

  private endLoading() {
    this.isLoading = false
    this.playIconTarget.classList.remove("fa-spinner")
    this.playIconTarget.classList.remove("fa-spin")
    this.playIconTarget.classList.add("fa-play")
  }

  private drawDots(data, svg) {
    const g = createGroup("dots")
    for (const d of data) {
      const { x, y, z, assetAnyId, managementCode, color, time, trackingStatus } = d
      const dot = this.buildDot(x, y, z, assetAnyId, color, time, trackingStatus)
      g.appendChild(dot)
      this.attachDotTooltip(dot, x, y, z, managementCode, time)
    }
    svg.appendChild(g)
  }

  private drawLines(data, svg) {
    let { x, y, assetAnyId, managementCode, color } = data[0]
    const g = createGroup("lines")
    data.forEach((d, i) => {
      if (i == 0) return

      if (managementCode == d.managementCode) {
        const line = this.buildLine(x, y, d.x, d.y, assetAnyId, color, d.time)
        g.appendChild(line)
      } else {
        color = d.color
        assetAnyId = d.assetAnyId
        managementCode = d.managementCode
      }
      x = d.x
      y = d.y
    })
    svg.appendChild(g)
  }

  private addDrawAnims(data) {
    // 時間を合わせるための全体に渡るdummy animationを追加
    this.anim.add({
      duration: this.flowlineDuration * 1000 / this.animSpeed,
    }, 0)

    // animation該当時間になったら changeBegin, changeCompletで d-noneを削除/追加する
    data.forEach((d) => {
      this.anim.add({
        duration: this.animDuration * 1000 / this.animSpeed,
        easing: "easeOutCirc",
        changeBegin: () => {
          document.querySelectorAll(`#flowline-${d.assetAnyId}-${d.time.format("YYYYMMDDHHmmss")}`).forEach(e => { e.classList.remove("d-none") })
        },
        changeComplete: () => {
          document.querySelectorAll(`#flowline-${d.assetAnyId}-${d.time.format("YYYYMMDDHHmmss")}`).forEach(e => { e.classList.add("d-none") })
        },
      }, (d.time - this.startTime) / this.animSpeed)
    })
    this.endLoading()
  }

  // 全てのsvgが描画されているか確認
  private checkAllSvgMap(): boolean {
    let flag = true
    const elements = document.getElementsByClassName("floor-svg")
    Array.from(elements).forEach(e => {
      if (e.getElementsByTagName("svg")[0] == undefined) {
        flag = false
      }
    })
    return flag
  }

  private buildData(x: string, y: string, z: string, assetAnyId: string, managementCode: string, color: string, at: string): Object {
    return {
      x: Math.floor((+x * 1000)) / 1000,
      y: Math.floor((+y * 1000)) / 1000,
      z: Math.floor((+z * 1000)) / 1000,
      assetAnyId: assetAnyId,
      managementCode: managementCode,
      color: color,
      time: moment(at)
    }
  }

  private buildDot(x: string, y: string, z: string, assetAnyId: string, color: string, time: moment.Moment, trackingStatus: string): HTMLElement {
    const circle = createPositionMaker(x, y, z, color, 0.1, trackingStatus)
    circle.setAttribute("style", `r: 0.1; fill: ${color};`)
    circle.setAttribute("class", "d-none")
    circle.setAttribute("id", `flowline-${assetAnyId}-${time.format('YYYYMMDDHHmmss')}`)
    return circle
  }

  private attachDotTooltip(dot: HTMLElement, x: string, y: string, z: string, managementCode: string, time: moment.Moment) {
    $(dot).tooltip({
      title: [
        `<span class="border-bottom">${managementCode}</span>`,
        `(${x}, ${y}, ${z})`,
        `${time.format()}`,
      ].join('<br>'),
      html: true,
      customClass: "asset-position",
    })
  }

  private buildLine(x1: string, y1: string, x2: string, y2: string, assetAnyId: string, color: string, time: moment.Moment): HTMLElement {
    const line = createLine(x1, y1, x2, y2, color)
    line.setAttribute("style", "stroke-width: 0.05; stroke-dasharray: 0.1, 0.1;")
    line.setAttribute("class", "d-none")
    line.setAttribute("id", `flowline-${assetAnyId}-${time.format('YYYYMMDDHHmmss')}`)
    return line
  }

  get svg() {
    return this.svgMapTarget.querySelector(this.selectorValue || 'svg')
  }
}
