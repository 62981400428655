import { Controller } from "@hotwired/stimulus"
import * as moment from "moment";

// アセットの位置を確認する検索フォームのコントローラー
export default class extends Controller {
  static values = { duration: Number };
  static targets = [ "baseAt", "duration" ];

  // XXX: 決まった時間を移動できるdatepickerとして汎用化できる気がしている。。
  declare baseAtTarget: HTMLInputElement & {_flatpickr: any};
  declare durationTarget: HTMLInputElement | HTMLSelectElement;
  declare durationValue: number;

  nextDuration(e: any) {
    e.preventDefault();
    this.baseAt = this.baseAtDate.add(this.duration, 's').format();
  }

  latestDuration(e: any) {
    e.preventDefault();
    this.baseAt = moment().subtract(this.duration, 's').format();
  }

  previousDuration(e: any) {
    e.preventDefault();
    this.baseAt = this.baseAtDate.subtract(this.duration, 's').format();
  }

  set baseAt(v) {
    if (this.baseAtTarget._flatpickr) {
      // flatpickr管理下の場合はflatpicekrに更新を依頼する
      this.baseAtTarget._flatpickr.setDate(v);
    } else {
      this.baseAtTarget.value = v;
    }
  }

  get baseAt() {
    return this.baseAtTarget.value;
  }

  get baseAtDate() {
    const d = moment(this.baseAt);
    return d.isValid() ? d : moment();
  }

  get duration() {
    return this.durationValue || this.durationTarget.value || this.defaultDuration;
  }

  get defaultDuration() {
    return 60;
  }
}

// <div data-controller="asset-positions--search">
//   <button data-action="asset-positions--search#previousDuration">prev</button>
//   <input type="text" data-asset-positions--search-target="baseAt">
//   <button data-action="asset-positions--search#nextDuration">next</button>
//   <input type="hidden" data-asset-positions--search-target="duration" value="60">
// </div>
