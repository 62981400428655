import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  visit() {
    const select = (this.element as HTMLSelectElement);
    const href = select.value;
    if (href) {
      Turbo.visit(href);
    }
  }
}
// 対象
// <select data-controller="select-link" data-action="select-link#visit">
//   <option value="/dashboard/groups/1">グループ1</option>
//   <option value="/dashboard/groups/2">グループ1</option>
//   <option value="/dashboard/groups/3">グループ1</option>
// </select>
