import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["drag"];

  declare dragClass: string;

  startTop: number;
  startLeft: number;
  startX: number;
  startY: number;
  boundClick: EventListenerOrEventListenerObject;
  boundDrag: EventListenerOrEventListenerObject;

  connect() {
    this.boundClick = this.click.bind(this);
    this.boundDrag = this.drag.bind(this);
    this.element.classList.add(this.dragClass);
    this.element.addEventListener("mousedown", this.boundClick, false);
  }

  click(event) {
    if (event.target.tagName != "INPUT") {
      event.preventDefault();
    }
    const element = this.element as HTMLElement;
    this.startX = event.pageX;
    this.startY = event.pageY;
    this.startTop = element.offsetTop;
    this.startLeft = element.offsetLeft;
    element.addEventListener("mousemove", this.boundDrag, false);
    document.body.addEventListener("mouseup", () => {
      element.removeEventListener("mousemove", this.boundDrag, false);
    }, false)
  }

  drag(event) {
    const element = this.element as HTMLElement;
    element.style.left = String(this.startLeft + event.pageX - this.startX) + "px";
    element.style.top = String(this.startTop + event.pageY - this.startY) + "px";
  }
}
