export function createGroup(id, inverse = true) {
  const g = createElementSvg("g");
  g.setAttribute("id", id)
  if (inverse) {
    g.setAttribute("transform", "scale(1,-1)");
  } else {
    g.setAttribute("transform", "scale(1,1)");
  }
  return g;
}

export function transformPoint(x, y, svg, inverse = true) {
  const point = svg.createSVGPoint();
  point.x = x;
  point.y = y;
  const matrix = point.matrixTransform(svg.getScreenCTM().inverse())
  point.x = matrix.x;

  if (inverse) {
    point.y = matrix.y * -1;
  } else {
    point.y = matrix.y;
  }
  return point;
}

export function createLine(x1, y1, x2, y2, color = "black") {
  const line = createElementSvg("line");
  line.setAttribute("x1", x1);
  line.setAttribute("x2", x2);
  line.setAttribute("y1", y1);
  line.setAttribute("y2", y2);
  line.setAttribute("stroke", color);
  line.setAttribute("stroke-width", 0.01);
  return line;
}

export function createPolygon(points, color = "blue", strokeWidth = 0.01, fillColor = "none", fillOpacity = 0.5) {
  const polygon = createElementSvg("polygon");
  polygon.setAttribute("fill", fillColor);
  polygon.setAttribute("fill-opacity", fillOpacity);
  polygon.setAttribute("points", points);
  polygon.setAttribute("stroke", color);
  polygon.setAttribute("stroke-width", strokeWidth);
  return polygon;
}

export function createPositionMaker(x, y, code, color, size = 0.1, trackingStatus = "tracking") {
  const marker = createElementSvg("circle");
  marker.setAttribute("cx", nanToZero(x));
  marker.setAttribute("cy", nanToZero(y));
  marker.setAttribute("r", size);
  marker.setAttribute("stroke", "black");
  marker.setAttribute("stroke-width", 0.01);
  marker.setAttribute("fill", color);
  marker.dataset.code = code;
  marker.dataset.trackingStatus = trackingStatus;
  return marker;
}

export function createLocatorMaker(x, y, code = null, color = "blue", size = 0.2) {
  const marker = createElementSvg("rect");
  marker.setAttribute("x", nanToZero(x) - size / 2);
  marker.setAttribute("y", nanToZero(y) - size / 2);
  marker.setAttribute("data-original-x", x);
  marker.setAttribute("data-original-y", y);
  marker.setAttribute("width", size);
  marker.setAttribute("height", size);
  marker.setAttribute("fill", color);
  marker.dataset.code = code;
  return marker;
}

export function createMarkerMarker(x, y, id = null, name = null, color = "blue", size = 0.1) {
  const marker = createElementSvg("circle");
  marker.setAttribute("cx", nanToZero(x));
  marker.setAttribute("cy", nanToZero(y));
  marker.setAttribute("r", size);
  marker.setAttribute("stroke", "black");
  marker.setAttribute("stroke-width", 0.01);
  marker.setAttribute("fill", color);
  marker.dataset.id = id;
  marker.dataset.name = name;
  return marker;
}

export function createMapMarker(x, y, code) {
  const marker = createElementSvg("use");
  marker.href.baseVal = "#marker";
  marker.setAttribute("x", x);
  marker.setAttribute("y", y);
  marker.setAttribute("width", 1);
  marker.setAttribute("height", 1);
  marker.dataset.code = code;
  return marker;
}

export function nanToZero(x) {
  return isNaN(x) ? 0 : x;
}

export function createElementSvg(tagName) {
  return document.createElementNS("http://www.w3.org/2000/svg", tagName);
}
