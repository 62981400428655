import { Controller } from "@hotwired/stimulus"
import { createElementSvg, createGroup, createPositionMaker, createPolygon, transformPoint } from '../../mixins/create_svg'
import intersect from "@turf/intersect";
import booleanContains from "@turf/boolean-contains";
import { point, polygon, multiPolygon, Feature, Polygon, MultiPolygon } from "@turf/helpers";

type PolygonPoint = {
  x: number;
  y: number;
}

export default class extends Controller {
  static values = { color: { type: String, default: "blue" }, fixed: { type: Number, default: 3 }, areaId: Number, areaGroupId: Number, inputScope: { type: String, default: "area" } };
  static targets = [ "polygon", "points", "submit", "pointForm", "pointX", "pointY", "copyPointTemplate" ];

  declare colorValue: string
  declare areaIdValue: number
  declare areaGroupIdValue: number
  declare hasAreaGroupIdValue: Boolean
  declare hasAreaIdValue: Boolean
  declare fixedValue: number
  declare inputScopeValue: string
  declare submitTarget: HTMLInputElement
  declare pointFormTarget: HTMLTemplateElement
  declare copyPointTemplateTarget: HTMLTemplateElement
  declare pointXTarget: HTMLInputElement
  declare pointYTarget: HTMLInputElement
  declare hasPointXTarget: Boolean
  declare hasPointYTarget: Boolean
  declare polygonTarget: SVGPolygonElement
  declare pointsTarget: HTMLInputElement
  polygonPoints: Array<PolygonPoint> = []
  regionMultiPolygon: Feature<MultiPolygon>
  regionPolygons: Array<Feature<Polygon>> = []
  filteredRegionPolygons: Array<Feature<Polygon>> = []
  activeMarker: SVGCircleElement
  selectedCircle: SVGCircleElement
  nearestPoint: PolygonPoint = { x: 0.1, y: 0.1 }

  setAction() {
    this.svg.addEventListener("mousedown", event => this.draw(event));
    this.appenndSvgPattern();
    this.regionPolygons = this.areaRegionsElement.map((area) => {
      this.setAreaCircle(area);
      const properties = { fill: area.getAttribute("fill"), id: Number(area.dataset.id), areaGroupId: Number(area.dataset.areaGroupId) };
      return polygon([this.getPolygonPoints(area).map(a => [a.x, a.y])], properties);
    });
    this.polygonTarget.setAttribute("fill", "url(#fill-pattern)");
    this.polygonTarget.setAttribute("stroke", this.colorValue);
    if (this.hasAreaGroupIdValue) {
      this.filteredRegionPolygons = this.regionPolygons.filter((polygon) => {
       return polygon.properties.areaGroupId === this.areaGroupIdValue &&
              polygon.properties.id != this.areaIdValue &&
              !isNaN(polygon.properties.id)
      });
    }

    this.pickupEditPolygoon();
    this.svg.addEventListener("mouseup", event => this.endDragCircle(event));
    this.svg.addEventListener("mousemove", event => this.dragCircle(event));
  }

  pickupEditPolygoon() {
    const regionElm = this.hasAreaIdValue ?
      this.areaRegionsElement.find(region => Number(region.dataset.id) === this.areaIdValue) :
      this.areaRegionsElement.find(region => region.dataset.id === String(null));

    if (regionElm) {
      const points = this.getPolygonPoints(regionElm);
      this.polygonPoints = points;
      regionElm.parentElement.remove();
      this.replacePolygonPoint();
      this.duplicatePolygon();
      this.setPoints();
      this.controlSubmit();
    }
  }

  appenndSvgPattern() {
    const defs = createElementSvg("defs");
    const pattern = createElementSvg("pattern");
    pattern.setAttribute("id", "fill-pattern")
    pattern.setAttribute("width", "0.2")
    pattern.setAttribute("height", "1")
    pattern.setAttribute("patternUnits", "userSpaceOnUse")
    pattern.setAttribute("patternTransform", "rotate(45 50 50)");
    const line1 = createElementSvg("line");
    line1.setAttribute("stroke", this.colorValue)
    line1.setAttribute("stroke-width", "0.1px")
    line1.setAttribute("y2", "5");
    pattern.appendChild(line1);
    const line2 = createElementSvg("line");
    line2.setAttribute("stroke", this.colorValue)
    line2.setAttribute("stroke-width", "1px");
    line2.setAttribute("stroke-opacity", "0.5");
    line2.setAttribute("y2", "5");
    pattern.appendChild(line2);
    defs.appendChild(pattern);
    this.svg.appendChild(defs);
  }

  setAreaCircle(area) {

    this.getPolygonPoints(area).forEach((point: PolygonPoint, idx) => {
      const marker = createPositionMaker(point.x, point.y, "", area.getAttribute("fill"));
      const selectPointTarget = this.copyPointTemplateTarget.content.firstElementChild.cloneNode(true) as HTMLElement;
      marker.setAttribute("data-idx", idx);
      marker.setAttribute("role", "button");

      $(marker).tooltip({
        container: '.floor-map',
        title: function() {
          const idx = Number(marker.getAttribute("data-idx")) + 1;
          const pointXTarget = selectPointTarget.querySelector("#select-point-x") as HTMLElement;
          const pointYTarget = selectPointTarget.querySelector("#select-point-y") as HTMLElement;
          const toastTitle = selectPointTarget.querySelector(".toast-header-title");
          const button = selectPointTarget.querySelector(".btn-point-copy") as HTMLButtonElement;
          const x = this.getAttribute("cx");
          const y = this.getAttribute("cy");
          toastTitle.textContent = `point ${idx}`;
          pointXTarget.innerText = x;
          pointYTarget.innerText = y;
          button.setAttribute("data-x", x);
          button.setAttribute("data-y", y);
          return selectPointTarget.innerHTML;
        },
        html: true,
        customClass: "locator-position copy-tooltip",
        sanitize: false,
        trigger: "manual",
      });

      marker.addEventListener("click", (event) => {
        event.preventDefault();
        $(marker).tooltip("show");
      });

      $(marker).on("show.bs.tooltip", () => {
        $('.copy-tooltip').not(marker).tooltip("hide");
      });

      $(marker).on("shown.bs.tooltip", () => {
        const tooltipElm = document.body.querySelector(".copy-tooltip");
        tooltipElm.setAttribute("data-draggable-drag-class", "drag");
        tooltipElm.setAttribute("data-controller", "draggable");
        $(".tooltip-inner [data-dismiss=tooltip]").on("click", function(e){
            $(marker).tooltip("hide");
        });
      });

      area.parentNode.appendChild(marker);
    })
  }

  draw(event) {
    if(event.altKey) {
      return;
    }

    if(event.target.tagName === "circle") {
      return;
    }

    const x = event.clientX;
    const y = event.clientY;

    const point = transformPoint(x, y, this.svg);
    const polygonPoint = { x: this.fixedNum(point.x), y: this.fixedNum(point.y) };
    if (!this.hasContainsPoint(polygonPoint.x, polygonPoint.y)) {
      this.polygonPoints.push(polygonPoint);

      this.polygonPoints = this.mergeNearestPoints();

      this.replacePolygonPoint();
      this.duplicatePolygon();
      this.setPoints();

      const circles = this.element.querySelectorAll(`#${this.polygoinPointsId} > circle`);
      $(circles[circles.length -1]).tooltip("show")

      this.controlSubmit();
    }
    event.stopImmediatePropagation();
  }

  input(event) {
    const x = parseFloat(parseFloat(this.pointXTarget.value).toFixed(this.fixedValue));
    const y = parseFloat(parseFloat(this.pointYTarget.value).toFixed(this.fixedValue));
    const idx = event.target.dataset.idx;

    const point: PolygonPoint = { x: x, y: y };
    this.polygonPoints.splice(idx, 1, point);
    this.polygonPoints = this.mergeNearestPoints();
    const nearestIndex = this.getNearestPointIndex(x, y);
    if (nearestIndex > -1) {
      this.pointXTarget.value = String(this.polygonPoints[nearestIndex].x);
      this.pointYTarget.value = String(this.polygonPoints[nearestIndex].y);
      const tooltipTitle = document.querySelector(".input-tooltip .toast-header-title");
      if (tooltipTitle) {
        tooltipTitle.textContent = `point ${nearestIndex + 1}`;
      }
    }

    this.replacePolygonPoint();
    this.duplicatePolygon();
    this.setPoints();
    this.controlSubmit();
  }

  copyPoint(event) {
    if (this.activeMarker) {
      const x = Number(event.target.dataset.x);
      const y = Number(event.target.dataset.y);
      const idx = Number(this.activeMarker.dataset.idx);
      const point: PolygonPoint = { x: x, y: y };
      this.polygonPoints.splice(idx, 1, point);
      if (this.hasPointXTarget) {
        this.pointXTarget.value = String(x);
      }
      if (this.hasPointYTarget) {
        this.pointYTarget.value = String(y);
      }

      this.replacePolygonPoint();
      this.duplicatePolygon();
      this.setPoints();
      this.controlSubmit();
    }
  }

  dragCircle(event) {
    if (this.selectedCircle) {
      event.preventDefault();

      $(".tooltip").tooltip("hide");
      const x = event.clientX;
      const y = event.clientY;
      const idx = Number(this.selectedCircle.getAttribute("data-idx"));

      const point = transformPoint(x, y, this.svg);
      const polygonPoint = { x: this.fixedNum(point.x), y: this.fixedNum(point.y) };
      this.polygonPoints.splice(idx, 1, polygonPoint);
      this.polygonPoints = this.mergeNearestPoints();
      const nearestIndex = this.getNearestPointIndex(polygonPoint.x, polygonPoint.y);
      if (nearestIndex > -1) {
        polygonPoint.x = this.polygonPoints[nearestIndex].x;
        polygonPoint.y = this.polygonPoints[nearestIndex].y;
        const tooltipTitle =  document.querySelector(".input-tooltip .toast-header-title");
        if (tooltipTitle) {
          tooltipTitle.textContent = `point ${nearestIndex + 1}`;
        }
      }


      if (this.hasPointXTarget) {
        this.pointXTarget.value = String(polygonPoint.x);
      }
      if (this.hasPointYTarget) {
        this.pointYTarget.value = String(polygonPoint.y);
      }

      this.replacePolygonPoint();
      this.duplicatePolygon();
      this.setPoints();
      this.controlSubmit();
    }
  }

  startDragCirle(event) {
    event.preventDefault();
    this.selectedCircle = event.target;
  }

  endDragCircle(event) {
    this.selectedCircle = null;
  }

  duplicatePolygon() {
    this.duplicatePolygonGroupReset();
    if (this.filteredRegionPolygons.length > 0 && this.polygonPoints.length > 2) {
      const points = [...this.polygonPoints.map(a => [a.x, a.y]), [this.polygonPoints[0].x, this.polygonPoints[0].y]];
      const geoPolygon = multiPolygon([[points]]);

      const g = createGroup(this.duplicatePolygonGroupId);

      this.filteredRegionPolygons.forEach((polygon) => {
        const intersection = intersect(polygon, geoPolygon);
        if(intersection) {
          const color = polygon.properties.fill;
          const polygonElm = createPolygon(intersection.geometry.coordinates[0], color, 0.01, color);
          polygonElm.classList.add("duplicate-polygon")
          g.appendChild(polygonElm);
        }
      })

      this.svg.appendChild(g);
    }
  }

  clear() {
    this.polygonTarget.points.clear();
    this.polygonPoints = [];
    this.pointsTarget.innerHTML = "";
    const polygoinPointsGroup = this.svg.getElementById(this.polygoinPointsId);
    if (polygoinPointsGroup) {
      polygoinPointsGroup.parentNode.removeChild(polygoinPointsGroup);
    }
    $('.tooltip').tooltip('hide');
    this.duplicatePolygonGroupReset();
    this.controlSubmit();
  }

  controlSubmit() {
    if (this.polygonTarget.points.length > 2) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  setPoints() {
    const polygoinPointsGroup = this.svg.getElementById(this.polygoinPointsId);
    if (polygoinPointsGroup) {
      polygoinPointsGroup.parentNode.removeChild(polygoinPointsGroup);
    }

    const g = createGroup(this.polygoinPointsId);
    this.pointsTarget.innerHTML = "";

    this.polygonPoints.forEach((point, idx) => {
      const marker = createPositionMaker(point.x, point.y, "", this.colorValue);
      marker.setAttribute("data-idx", idx);

      this.setInputPoint(marker, point);
      this.setHiddenParams(point);
      g.appendChild(marker);
    });

    this.svg.appendChild(g);
  }

  setHiddenParams(point) {
    const pointXField = document.createElement("input");
    const pointYField = document.createElement("input");

    pointXField.setAttribute("type", "hidden");
    pointXField.setAttribute("name", `${this.inputScopeValue}[points][][x]`);
    pointYField.setAttribute("type", "hidden");
    pointYField.setAttribute("name", `${this.inputScopeValue}[points][][y]`);
    pointXField.value = String(point.x);
    pointYField.value = String(point.y);
    this.pointsTarget.appendChild(pointXField);
    this.pointsTarget.appendChild(pointYField);
  }

  setInputPoint(marker, point) {
    const pointFormTarget = this.pointFormTarget.content.firstElementChild.cloneNode(true) as HTMLElement;

    marker.setAttribute("role", "button");

    $(marker).tooltip({
      container: '.floor-map',
      title: function() {
        const idx = Number(marker.getAttribute("data-idx")) + 1;
        const pointXTarget = pointFormTarget.querySelector("#inputPointX") as HTMLInputElement;
        const pointYTarget = pointFormTarget.querySelector("#inputPointY") as HTMLInputElement;
        const toastTitle = pointFormTarget.querySelector(".toast-header-title");
        toastTitle.textContent = `point ${idx}`;
        pointXTarget.defaultValue = this.getAttribute("cx");
        pointYTarget.defaultValue = this.getAttribute("cy");
        pointXTarget.setAttribute("data-idx", this.getAttribute("data-idx"));
        pointYTarget.setAttribute("data-idx", this.getAttribute("data-idx"));
        return pointFormTarget.innerHTML;
      },
      html: true,
      customClass: "locator-position input-tooltip",
      sanitize: false,
      trigger: "manual",
    });

    marker.addEventListener("click", (event) => {
      event.preventDefault();
      $(marker).tooltip("show");
    });

    this.svg.addEventListener("mousedown", event => this.startDragCirle(event));

    $(marker).on("show.bs.tooltip", () => {
      this.activeMarker = marker;
      $('.input-tooltip').not(marker).tooltip("hide");
    });

    $(marker).on("shown.bs.tooltip", () => {
      const tooltipElm = document.body.querySelector(".input-tooltip");
      tooltipElm.setAttribute("data-draggable-drag-class", "drag");
      tooltipElm.setAttribute("data-controller", "draggable");
      $(".tooltip-inner [data-dismiss=tooltip]").on("click", function(e){
          $(marker).tooltip("hide");
      });
    });
  }

  deletePoint() {
    if (this.activeMarker) {
      const idx = Number(this.activeMarker.dataset.idx);
      this.polygonPoints.splice(idx, 1);

      this.replacePolygonPoint();
      this.duplicatePolygon();
      this.setPoints();
      this.controlSubmit();

      $(this.activeMarker).tooltip("hide");
      this.activeMarker = null;
    }
  }

  private mergeNearestPoints(): Array<PolygonPoint> {
    let polygonPoints = [];
    this.polygonPoints.forEach((point) => {
      if(!this.hasNearestPoint(polygonPoints, point.x, point.y)) {
        polygonPoints.push(point);
      }
    });

    return polygonPoints;
  }

  private getNearestPointIndex(x: number, y: number): number {
    return this.polygonPoints.findIndex((point) => {
      return (x - this.nearestPoint.x < point.x && point.x <= x + this.nearestPoint.x) &&
             (y - this.nearestPoint.y < point.y && point.y <= y + this.nearestPoint.y)
    });
  }

  private hasNearestPoint(points: Array<PolygonPoint>, x: number, y: number): boolean {
    return points.some((point) => {
      return (x - this.nearestPoint.x < point.x && point.x <= x + this.nearestPoint.x) &&
             (y - this.nearestPoint.y < point.y && point.y <= y + this.nearestPoint.y)
    });
  }

  private getPolygonPoints(area): Array<PolygonPoint> {
    const polygonPoints = area.getAttribute("points").split(" ").map((p) => {
      const point = p.split(",");
      return { x: this.fixedNum(Number(point[0])), y: this.fixedNum(Number(point[1])) };
    });
    return polygonPoints;
  }

  private replacePolygonPoint() {
    this.polygonTarget.setAttribute("points", this.polygonPoints.map(point => `${point.x},${point.y}`).join(" "));
  }

  private fixedNum(val: number): number {
    return parseFloat(val.toFixed(this.fixedValue));
  }

  private duplicatePolygonGroupReset() {
    const duplicatePolygonGroup = this.svg.getElementById(this.duplicatePolygonGroupId);
    if (duplicatePolygonGroup) {
      duplicatePolygonGroup.parentNode.removeChild(duplicatePolygonGroup);
    }
  }

  private hasContainsPoint(x: number, y: number): boolean {
    if (this.filteredRegionPolygons.length > 0) {
      return this.filteredRegionPolygons.some(polygon => booleanContains(polygon, point([x, y])));
    } else {
      return false;
    }
  }

  get areaRegionsElement(): Array<SVGPolygonElement> {
    return Array.from(this.element.querySelectorAll("#__area_region__ > g > polygon"));
  }

  get svg(): SVGSVGElement {
    return this.element.getElementsByClassName("svg-map")[0].getElementsByTagName("svg")[0];
  }

  get polygoinPointsId() {
    return "__polygon__points__";
  }

  get duplicatePolygonGroupId() {
    return "__duplicate_polygon__";
  }
}
