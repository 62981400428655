import { createGroup, createElementSvg } from './create_svg'

export const renderAreaRegion = controller => {
  Object.assign(controller, {
    replaceAreaRegion() {
      const areaRegionElement = this.svg.getElementById(this.areaRegionId);
      if (areaRegionElement) {
        areaRegionElement.parentNode.removeChild(areaRegionElement);
      }
      this.svg.appendChild(this.createAreaRegion());
    },

    createAreaRegion() {
      const g = createGroup(this.areaRegionId);
      const newAreaRegionElement = createElementSvg("polygon");
      newAreaRegionElement.setAttribute("data-floors--areas-target", "polygon");
      newAreaRegionElement.setAttribute("stroke", "blue");
      newAreaRegionElement.setAttribute("fill", "blue");
      newAreaRegionElement.setAttribute("fill-opacity", "0.5");
      g.appendChild(newAreaRegionElement);
      for (const areaRegion of this.areaRegionsValue) {
        const {id, polygons, opacity, color, url, area_group_id} = areaRegion;
        const polygonAreaGroup = createGroup(`__area_polygon_${id}__`, false);
        polygons.forEach((polygon) => {
          const areaRegionElement = this.createPolygon(polygon.points, id, color, opacity, url);
          areaRegionElement.setAttribute("data-area-group-id", area_group_id);
          polygonAreaGroup.appendChild(areaRegionElement);
        })
        g.appendChild(polygonAreaGroup);
      }
      return g;
    },

    createPolygon(points, id, color, opacity, url) {
      const areaRegionElement = createElementSvg("polygon");
      const polygonPoints = points.map((p) => (p.join(","))).join(" ");
      areaRegionElement.setAttribute("data-id", id);
      if (color) {
        areaRegionElement.setAttribute("stroke", color);
        areaRegionElement.setAttribute("fill", color);
      } else {
        areaRegionElement.setAttribute("stroke", "orange");
        areaRegionElement.setAttribute("fill", "orange");
      }
      areaRegionElement.setAttribute("fill-opacity", "0.5");
      areaRegionElement.setAttribute("points", polygonPoints)
      if (opacity) {
        areaRegionElement.setAttribute("opacity", opacity);
      }

      if (url) {
        areaRegionElement.style.cursor = "pointer";
        areaRegionElement.addEventListener("click", () => location.href = url);
      }
      const toastElement = document.getElementById(`area_popover_area_${id}`);

      if (toastElement) {
        $(areaRegionElement).tooltip({
          container: 'body',
          title: toastElement.innerHTML,
          html: true,
          customClass: "area-region",
          sanitize: false,
          trigger: "hover focus",
        })
      }
      return areaRegionElement;
    },

    get areaRegionId() {
      return "__area_region__";
    }
  })
}
