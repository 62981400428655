import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { parse } from "papaparse";
import lang from "lodash/lang";

export default class extends Controller {
  static values = {
    markerIdColumn: String, areaGroupIdColumn: String, assetColumn: String, timeColumn: String,
    trackingStatusColumn: String, trackingStatusChecked: String, trackingStatusUnchecked: String,
    thresholdEpsilonColumn: String, thresholdSigmaColumn: String, thresholdOutputRateColumn: String, thresholdAreaMatchRateColumn: String
  };
  static targets = ["form", "tbody", "checkbox", "marker", "area", "asset", "time", "epsilon", "sigma",
    "outputRate", "areaMatchRate", "thresholdEpsilon", "thresholdSigma", "thresholdOutputRate",
    "trackingStatus", "areaGroupId", "thresholdAreaMatchRate", "csvFile", "bulkTime"];

  declare markerIdColumnValue: string;
  declare areaGroupIdColumnValue: string;
  declare assetColumnValue: string;
  declare timeColumnValue: string;
  declare trackingStatusColumnValue: string;
  declare trackingStatusCheckedValue: string;
  declare trackingStatusUncheckedValue: string;
  declare thresholdEpsilonColumnValue: string;
  declare thresholdSigmaColumnValue: string;
  declare thresholdOutputRateColumnValue: string;
  declare thresholdAreaMatchRateColumnValue: string;

  declare formTarget: HTMLFormElement;
  declare tbodyTarget: HTMLTableSectionElement;
  declare checkboxTargets: HTMLInputElement[];
  declare markerTargets: HTMLInputElement[];
  declare areaTargets: HTMLInputElement[];
  declare assetTargets: HTMLInputElement[];
  declare timeTargets: HTMLInputElement[];
  declare epsilonTargets: HTMLInputElement[];
  declare sigmaTargets: HTMLInputElement[];
  declare outputRateTargets: HTMLInputElement[];
  declare areaMatchRateTargets: HTMLInputElement[];
  declare trackingStatusTarget: HTMLInputElement;
  declare areaGroupIdTarget: HTMLSelectElement;
  declare thresholdEpsilonTarget: HTMLInputElement;
  declare thresholdSigmaTarget: HTMLInputElement;
  declare thresholdOutputRateTarget: HTMLInputElement;
  declare thresholdAreaMatchRateTarget: HTMLInputElement;
  declare csvFileTarget: HTMLInputElement;
  declare bulkTimeTarget: HTMLInputElement;

  readonly CSV_FORMAT_ERROR = "CSVファイルのフォーマットが不正です。エクスポートされたCSVファイルをインポートしてください。";
  readonly CSV_HEADERS = [
    "マーカーID",
    "マーカー名",
    "マーカー X座標",
    "マーカー Y座標",
    "分析エリアグループID",
    "分析エリアグループ名",
    "分析エリアID",
    "分析エリア名",
    "アセット製造コード",
    "アセット名",
    "時刻",
    "平均誤差 [m]",
    "標準偏差 [m]",
    "位置出力率 [%]",
    "位置出力数",
    "分析エリア一致率 [%]",
    "分析エリア一致数",
    "最大位置出力数",
    "平均誤差の閾値 [m]",
    "標準偏差の閾値 [m]",
    "位置出力率の閾値 [%]",
    "分析エリア一致率の閾値 [%]",
    "追跡状態",
    "判定結果"
  ];

  verify(event) {
    const idx = event.target.closest("tr").id;
    this.verifyRow(idx);
  }

  updateThreshold(event) {
    if (event.target.id == "thresholdEpsilon") {
      this.epsilonTargets.forEach(target => {
        this.validateThreshold(target, event.target.value);
      });
    } else if (event.target.id == "thresholdSigma") {
      this.sigmaTargets.forEach(target => {
        this.validateThreshold(target, event.target.value);
      });
    } else if (event.target.id == "thresholdOutputRate") {
      this.outputRateTargets.forEach(target => {
        this.validateThreshold(target, event.target.value, true);
      });
    } else if (event.target.id == "thresholdAreaMatchRate") {
      this.areaMatchRateTargets.forEach(target => {
        this.validateThreshold(target, event.target.value, true);
      });
    }
  }

  async updateArea(event) {
    const areaGroupId = this.areaGroupIdTarget.value;
    const markerMap = await this.fetchMarkerMap(areaGroupId);

    this.areaTargets.forEach(target => {
      const idx = target.closest("tr").id;
      const markerId = this.markerTargets[idx].dataset.id;
      target.value = markerMap[markerId].area_name;
      if (markerMap[markerId].area_id == null) {
        target.dataset.id = '';
      } else {
        target.dataset.id = markerMap[markerId].area_id;
      }
      target.dispatchEvent(new Event("change"));
    });
  }

  async updateTrackingStatus(event) {
    this.markerTargets.forEach(target => {
      const idx = target.closest("tr").id;
      this.verifyRow(idx);
    });
  }

  export(event) {
    this.formTarget.setAttribute("action", event.target.dataset.url);
    this.formTarget.submit();
  }

  import(event) {
    const file = this.csvFileTarget.files[0];
    if (file == null) return;

    parse(file, {
      encoding: "utf8",
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {

        if (results.errors.length > 0 || !lang.isEqual(this.CSV_HEADERS, results.meta.fields)) {
          alert(this.CSV_FORMAT_ERROR);
          return;
        }

        this.setTrackingStatusFromCsv(results.data[0]);
        this.setThresholdsFromCsv(results.data[0]);
        this.setAreaGroupIdFromCsv(results.data[0]);
        this.clearTable();
        await this.updateArea(null);
        results.data.forEach(row => {
          this.markerTargets.forEach(target => {
            if (target.id == row[this.markerIdColumnValue]) {
              const idx = target.closest("tr").id;
              this.assetTargets[idx].value = row[this.assetColumnValue];
              $(this.assetTargets[idx]).select2();
              this.timeTargets[idx].value = row[this.timeColumnValue];
              this.verifyRow(idx);
            }
          });
        });
        $("#csv-imports").modal("hide");
      },
    });
  }

  setBulkTime(event) {
    if (this.bulkTimeTarget.value == "") return;

    this.timeTargets.forEach(target => {
      const idx = target.closest("tr").id;
      if (this.checkboxTargets[idx].checked) {
        const t = this.timeTargets[idx] as any;
        t._flatpickr.setDate(this.bulkTimeTarget.value);
        this.verifyRow(idx);
      }
    });
    $("#set-time-modal").modal("hide");
  }

  private async verifyRow(idx) {
    const markerUrl = this.markerTargets[idx].dataset.url;
    const code = this.assetTargets[idx].value;
    const time = this.timeTargets[idx].value;
    const trackingStatus = this.trackingStatusTarget.checked ? this.trackingStatusCheckedValue : this.trackingStatusUncheckedValue;
    const areaId = this.areaTargets[idx].dataset.id;
    const hasArea = areaId != '';

    if (markerUrl == "" || code == "" || time == "") return;

    const response = await this.fetchVerification(markerUrl, code, time, trackingStatus, areaId);
    this.epsilonTargets[idx].value = response.results.epsilon;
    this.sigmaTargets[idx].value = response.results.sigma;
    this.outputRateTargets[idx].value = `${response.results.output_rate} (${response.results.output_count}/${response.results.total_count})`;
    if (hasArea) {
      this.areaMatchRateTargets[idx].value = `${response.results.area_match_rate} (${response.results.area_match_count}/${response.results.total_count})`;
    } else {
      this.areaMatchRateTargets[idx].value = '-';
    }

    this.validateThreshold(this.epsilonTargets[idx], Number(this.thresholdEpsilonTarget.value));
    this.validateThreshold(this.sigmaTargets[idx], Number(this.thresholdSigmaTarget.value));
    this.validateThreshold(this.outputRateTargets[idx], Number(this.thresholdOutputRateTarget.value), true);
    this.validateThreshold(this.areaMatchRateTargets[idx], Number(this.thresholdAreaMatchRateTarget.value), true);
  }

  private async fetchVerification(markerUrl, code, time, trackingStatus, areaId) {
    const response = await get(markerUrl,
      { query: { code: code, time: time, tracking_status: trackingStatus, area_id: areaId }, cache: "no-cache" });
    return response.json;
  }

  private validateThreshold(target, threshold, lower = false) {
    target.classList.remove("border-danger");
    if (target.value == "" || target.value == "-") return;

    const targetValue = Number(target.value.split(" ")[0]);
    if (lower) {
      if (targetValue < threshold) target.classList.add("border-danger");
    } else {
      if (threshold < targetValue) target.classList.add("border-danger");
    }
  }

  private async fetchMarkers(areaGroupId) {
    const response = await get(`${window.location.href}.json`, { query: { area_group_id: areaGroupId }, cache: "no-cache" });
    return response.json;
  }

  private async fetchMarkerMap(areaGroupId) {
    const markers = await this.fetchMarkers(areaGroupId);
    const markerMap = markers.reduce((acc, m) => {
      acc[m.id] = m;
      return acc;
    }, {})
    return markerMap;
  }

  private setTrackingStatusFromCsv(row) {
    const csvValue = row[this.trackingStatusColumnValue];
    this.trackingStatusTarget.checked = csvValue != this.trackingStatusUncheckedValue;
  }

  private setThresholdsFromCsv(row) {
    this.thresholdEpsilonTarget.value = row[this.thresholdEpsilonColumnValue];
    this.thresholdSigmaTarget.value = row[this.thresholdSigmaColumnValue];
    this.thresholdOutputRateTarget.value = row[this.thresholdOutputRateColumnValue];
    this.thresholdAreaMatchRateTarget.value = row[this.thresholdAreaMatchRateColumnValue];
  }

  private setAreaGroupIdFromCsv(row) {
    this.areaGroupIdTarget.value = row[this.areaGroupIdColumnValue];
  }

  private clearTable() {
    this.assetTargets.forEach(target => {
      target.value = "";
    });
    this.timeTargets.forEach(target => {
      target.value = "";
    });
    this.epsilonTargets.forEach(target => {
      target.value = "";
      target.classList.remove("border-danger");
    });
    this.sigmaTargets.forEach(target => {
      target.value = "";
      target.classList.remove("border-danger");
    });
    this.outputRateTargets.forEach(target => {
      target.value = "";
      target.classList.remove("border-danger");
    });
    this.areaMatchRateTargets.forEach(target => {
      target.value = "-";
      target.classList.remove("border-danger");
    });
  }
}
