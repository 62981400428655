import { Controller } from "@hotwired/stimulus";
import {
  AmbientLight,
  CylinderGeometry,
  TorusGeometry,
  DirectionalLight,
  GridHelper,
  Euler,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Quaternion,
  Scene,
  Vector3,
  AxesHelper,
  WebGLRenderer,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

// three.js と hackke はどちらも右手系座標だが
// three.jsでは +x が右向き, +y が上向き, +z が手前向きで
// hackkeでは +x が右向き, +y が奥向き, +z が上向きである
export default class extends Controller {
  static targets = ["wrapper", "canvas", "rotX", "rotY", "rotZ", "posX", "posY", "posZ",
    "posXLabel", "posYLabel", "posZLabel", "inputPosX", "inputPosY", "inputPosZ",
    "isRefPos", "refPosX", "refPosY", "refPosZ", "diffPosX", "diffPosY", "diffPosZ",
    "diffPosXLabel", "diffPosYLabel", "diffPosZLabel", "diffRefToCenterX", "diffRefToCenterY",
    "diffRefToCenterZ", "locatorPath", "preset"];

  declare wrapperTarget: HTMLElement;
  declare canvasTarget: HTMLElement;
  declare rotXTarget: HTMLInputElement;
  declare rotYTarget: HTMLInputElement;
  declare rotZTarget: HTMLInputElement;
  declare posXTarget: HTMLInputElement;
  declare posYTarget: HTMLInputElement;
  declare posZTarget: HTMLInputElement;
  declare posXLabelTarget: HTMLElement;
  declare posYLabelTarget: HTMLElement;
  declare posZLabelTarget: HTMLElement;
  declare inputPosXTarget: HTMLInputElement;
  declare inputPosYTarget: HTMLInputElement;
  declare inputPosZTarget: HTMLInputElement;
  declare isRefPosTarget: HTMLInputElement;
  declare refPosXTarget: HTMLInputElement;
  declare refPosYTarget: HTMLInputElement;
  declare refPosZTarget: HTMLInputElement;
  declare diffPosXTarget: HTMLInputElement;
  declare diffPosYTarget: HTMLInputElement;
  declare diffPosZTarget: HTMLInputElement;
  declare diffPosXLabelTarget: HTMLElement;
  declare diffPosYLabelTarget: HTMLElement;
  declare diffPosZLabelTarget: HTMLElement;
  declare diffRefToCenterXTarget: HTMLInputElement;
  declare diffRefToCenterYTarget: HTMLInputElement;
  declare diffRefToCenterZTarget: HTMLInputElement;
  declare locatorPathTarget: HTMLElement;
  declare presetTarget: HTMLSelectElement;
  width: number;
  height: number;
  renderer: WebGLRenderer;
  scene: Scene;
  camera: PerspectiveCamera;
  locator: Mesh;
  quaternion: Quaternion;
  grid: GridHelper;
  isRotating: boolean;
  orbitCtl: OrbitControls;

  readonly REF_LABEL_X: string = "基準点X [m]";
  readonly REF_LABEL_Y: string = "基準点Y [m]";
  readonly REF_LABEL_Z: string = "基準点Z [m]";
  readonly ANTENNA_LABEL_X: string = "アンテナ中心点X [m]";
  readonly ANTENNA_LABEL_Y: string = "アンテナ中心点Y [m]";
  readonly ANTENNA_LABEL_Z: string = "アンテナ中心点Z [m]";

  connect() {
    this.render();
  }

  render() {
    this.width = $(this.wrapperTarget).width();
    this.height = this.width / 2.4;
    this.initInputPos();
    this.initPosLabels();

    // WebGL Renderer
    this.createRenderer();

    // Scene
    this.scene = new Scene();

    // Grid, 床として表現する
    this.createGrid();

    // Camera視点
    this.createCamera();

    // 光源
    this.createLight();

    // ポインタ操作によるカメラの配置とアングル制御
    this.createOrbitControls();

    // 基準となる座標軸
    this.createAxes();

    // ロケーター(Mesh)
    this.createLocator();

    // レンダリング
    this.renderer.render(this.scene, this.camera);

    // イベント処理
    this.canvasTarget.addEventListener("mousedown", (event: any) =>
      this.onMouseDown(event)
    );

    // プリセット
    this.setPreset();
  }

  // three.jsでは +x が右向き, +y が上向き, +z が手前向きだが
  // hackkeでは +x が右向き, +y が奥向き, +z が上向きのため
  // カメラ位置を変え -y から原点を見るように
  resetCamera() {
    this.camera.position.set(0, -640, 100);
    this.camera.lookAt(new Vector3());
    this.renderer.render(this.scene, this.camera);
    if (this.orbitCtl) {
      this.orbitCtl.reset();
    }
  }

  // X,Y,Z軸の回転角からquaternionを算出し, ロケーターとジンバルの回転および
  // アンテナ中心へのベクトルを回転させ差分距離を算出し書き込む
  rotateLocator() {
    const { rotX, rotY, rotZ } = this.getRot();
    this.quaternion = this.calcQuaternion(this.toRadians(rotX), this.toRadians(rotY), this.toRadians(rotZ));
    this.locator.setRotationFromQuaternion(this.quaternion);

    this.renderer.render(this.scene, this.camera);
    if (this.isRefPosTarget.checked) this.calcAntennaCenter();
  }

  changeRefPosMode() {
    this.initPosLabels();
    if (this.isRefPosTarget.checked) {
      this.calcAntennaCenter();
    } else {
      const { inputPosX, inputPosY, inputPosZ } = this.getInputPos();
      this.setPos(inputPosX, inputPosY, inputPosZ);
      this.setRefPos(0, 0, 0);
      this.setDiffPos(0, 0, 0);
      this.setDiffPosLabels(0, 0, 0);
    }
  }

  applyPreset(e: Event) {
    const select = e.target as HTMLSelectElement;
    const xyz = select.value.split("_");
    this.rotXTarget.value = xyz[0];
    this.rotYTarget.value = xyz[1];
    this.rotZTarget.value = xyz[2];
    this.rotateLocator();
  }

  setPreset() {
    const { rotX, rotY, rotZ } = this.getRot();
    const xyz = `${rotX}_${rotY}_${rotZ}`;
    const option = this.presetTarget.querySelector(`option[value="${xyz}"]`) as HTMLOptionElement;
    if (option) {
      option.selected = true;
    } else {
      const option = this.presetTarget.querySelector(`option[value="0_0_0"]`) as HTMLOptionElement;
      option.selected = true;
    }
  }

  highlight(e: Event) {
    const target = e.target as HTMLSelectElement;
    this.renderer.render(this.scene, this.camera);
  }

  unHighlight(e: Event) {
    const target = e.target as HTMLSelectElement;
    this.renderer.render(this.scene, this.camera);
  }

  // intrinsic euler angles to quaternion
  private calcQuaternion(radX: number, radY: number, radZ: number): Quaternion {
    const q1 = new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), radX);
    const q2 = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), radY);
    const q3 = new Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), radZ);
    return q3.multiply(q2).multiply(q1);
  }

  // ロケーター基準点からアンテナ中心点への線分をベクトルにし, queternionによってそのベクトルの回転座標計算を行い差分を算出する
  // アンテナ中心点XYZ = 基準点XYZ + 算出されたdiffXYZ
  private calcAntennaCenter() {
    const vec = new Vector3(
      Number(this.diffRefToCenterXTarget.value),
      Number(this.diffRefToCenterYTarget.value),
      Number(this.diffRefToCenterZTarget.value));
    vec.applyQuaternion(this.quaternion);
    const diffPosX = Number(parseFloat(vec.x.toString()).toFixed(3));
    const diffPosY = Number(parseFloat(vec.y.toString()).toFixed(3));
    const diffPosZ = Number(parseFloat(vec.z.toString()).toFixed(3));
    const { inputPosX, inputPosY, inputPosZ } = this.getInputPos();
    this.setRefPos(inputPosX, inputPosY, inputPosZ);
    this.setDiffPosLabels(Math.round(diffPosX * 1000), Math.round(diffPosY * 1000), Math.round(diffPosZ * 1000));
    this.setDiffPos(diffPosX, diffPosY, diffPosZ);
    this.setPos(
      Number(parseFloat((inputPosX + diffPosX).toString()).toFixed(3)),
      Number(parseFloat((inputPosY + diffPosY).toString()).toFixed(3)),
      Number(parseFloat((inputPosZ + diffPosZ).toString()).toFixed(3)),
    );
  }

  private createRenderer() {
    this.renderer = new WebGLRenderer({
      canvas: this.canvasTarget,
      antialias: true,
    });
    this.renderer.setSize(this.width, this.height, false);
    this.renderer.setClearColor(0xeeeeee);
  }

  private createGrid() {
    const grid = new GridHelper(this.width, 20);
    grid.lookAt(new Vector3(0, 1, 0));
    this.scene.add(grid);
  }

  private createCamera() {
    const fov = 50;
    const near = 1;
    const far = 1500;
    this.camera = new PerspectiveCamera(
      fov,
      this.width / this.height,
      near,
      far
    );
    this.resetCamera();
  }

  private createOrbitControls() {
    this.orbitCtl = new OrbitControls(this.camera, this.renderer.domElement);

    // カメラ距離の固定化
    this.orbitCtl.minDistance = 640;
    this.orbitCtl.maxDistance = 640;
  }

  private createLight() {
    const topLight = new DirectionalLight(0xeeeeee, 0.5);
    topLight.position.set(0, 0, 300);
    topLight.lookAt(new Vector3(0, 0, 0));
    this.scene.add(topLight);

    const groundLight = new DirectionalLight(0xeeeeee, 1.4);
    groundLight.position.set(0, 0, -10);
    groundLight.lookAt(new Vector3(0, 0, 1));
    this.scene.add(groundLight);

    const frontLight = new DirectionalLight(0xeeeeee, 0.5);
    frontLight.position.set(0, -300, 180);
    frontLight.lookAt(new Vector3(0, 0, 300));
    this.scene.add(frontLight);

    const rightLight = new DirectionalLight(0xeeeeee, 0.7);
    rightLight.position.set(300, 0, 180);
    rightLight.lookAt(new Vector3(0, 0, 300));
    this.scene.add(rightLight);

    const leftLight = new DirectionalLight(0xeeeeee, 0.7);
    leftLight.position.set(-300, 0, 180);
    leftLight.lookAt(new Vector3(0, 0, 300));
    this.scene.add(leftLight);

    const ambientLight = new AmbientLight(0xeeeeee, 0.2);
    this.scene.add(ambientLight);
  }

  private createAxes() {
    const axesX = new Mesh(
      new CylinderGeometry(3, 3, 250, 50),
      new MeshBasicMaterial({ color: 0xff8080 }),
    )
    axesX.rotateZ(this.toRadians(90));
    axesX.position.set(-125, 0, 0);
    this.scene.add(axesX);

    const axesY = new Mesh(
      new CylinderGeometry(3, 3, 250, 50),
      new MeshBasicMaterial({ color: 0x80ff80 }),
    )
    axesY.position.set(-250, 125, 0);
    this.scene.add(axesY);

    const axesZ = new Mesh(
      new CylinderGeometry(3, 3, 250, 50),
      new MeshBasicMaterial({ color: 0x8080ff }),
    )
    axesZ.rotateX(this.toRadians(90));
    axesZ.position.set(-250, 0, 125);
    this.scene.add(axesZ);
  }

  private createLocator() {
    const loader = new GLTFLoader();
    loader.load(this.locatorPathTarget.innerText, function (gltf: GLTF) {
      this.locator = gltf.scene;
      this.locator.scale.set(580, 580, 580);
      this.locator.position.set(0, 0, 160);
      const axes = new AxesHelper(0.2);
      this.locator.add(axes);
      this.rotateLocator();
      this.scene.add(this.locator);
      this.renderer.render(this.scene, this.camera);
    }.bind(this));
  }

  private onMouseDown(event: any) {
    event.preventDefault();
    this.isRotating = true;
    this.canvasTarget.addEventListener("mousemove", () => this.onMouseMove());
    this.canvasTarget.addEventListener("mouseup", () => this.onMouseUp());
  }

  private onMouseMove() {
    if (!this.isRotating) {
      return;
    }
    this.tick();
  }

  private onMouseUp() {
    this.isRotating = false;
    this.canvasTarget.removeEventListener("mousemove", this.onMouseMove);
    this.canvasTarget.removeEventListener("mouseup", this.onMouseUp);
  }

  private tick() {
    if (!this) return;
    this.orbitCtl.update();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.tick);
  }


  private initInputPos() {
    if (this.isRefPosTarget.checked) {
      const { refPosX, refPosY, refPosZ } = this.getRefPos();
      this.setInputPos(refPosX, refPosY, refPosZ);
    } else {
      const { posX, posY, posZ } = this.getPos();
      this.setInputPos(posX, posY, posZ);
    }
  }

  private getInputPos() {
    const inputPosX = Number(this.inputPosXTarget.value);
    const inputPosY = Number(this.inputPosYTarget.value);
    const inputPosZ = Number(this.inputPosZTarget.value);
    return { inputPosX, inputPosY, inputPosZ };
  }

  private setInputPos(inputPosX: number, inputPosY: number, inputPosZ: number) {
    this.inputPosXTarget.value = inputPosX.toString();
    this.inputPosYTarget.value = inputPosY.toString();
    this.inputPosZTarget.value = inputPosZ.toString();
  }

  private initPosLabels() {
    if (this.isRefPosTarget.checked) {
      this.setPosLabels(this.REF_LABEL_X, this.REF_LABEL_Y, this.REF_LABEL_Z);
    } else {
      this.setPosLabels(this.ANTENNA_LABEL_X, this.ANTENNA_LABEL_Y, this.ANTENNA_LABEL_Z);
    }
  }

  private setPosLabels(posXLabel: string, posYLabel: string, posZLabel: string) {
    $(this.posXLabelTarget).text(posXLabel);
    $(this.posYLabelTarget).text(posYLabel);
    $(this.posZLabelTarget).text(posZLabel);
  }

  private getRot() {
    const rotX = Number(this.rotXTarget.value);
    const rotY = Number(this.rotYTarget.value);
    const rotZ = Number(this.rotZTarget.value);
    return { rotX, rotY, rotZ };
  }

  private getPos() {
    const posX = Number(this.posXTarget.value);
    const posY = Number(this.posYTarget.value);
    const posZ = Number(this.posZTarget.value);
    return { posX, posY, posZ };
  }

  private setPos(posX: number, posY: number, posZ: number) {
    this.posXTarget.value = posX.toString();
    this.posYTarget.value = posY.toString();
    this.posZTarget.value = posZ.toString();
  }

  private getRefPos() {
    const refPosX = Number(this.refPosXTarget.value);
    const refPosY = Number(this.refPosYTarget.value);
    const refPosZ = Number(this.refPosZTarget.value);
    return { refPosX, refPosY, refPosZ };
  }

  private setRefPos(refPosX: number, refPosY: number, refPosZ: number) {
    this.refPosXTarget.value = refPosX.toString();
    this.refPosYTarget.value = refPosY.toString();
    this.refPosZTarget.value = refPosZ.toString();
  }

  private setDiffPos(diffPosX: number, diffPosY: number, diffPosZ: number) {
    this.diffPosXTarget.value = diffPosX.toString();
    this.diffPosYTarget.value = diffPosY.toString();
    this.diffPosZTarget.value = diffPosZ.toString();
  }

  private setDiffPosLabels(diffPosX: number, diffPosY: number, diffPosZ: number) {
    this.diffPosXLabelTarget.innerText = diffPosX.toString();
    this.diffPosYLabelTarget.innerText = diffPosY.toString();
    this.diffPosZLabelTarget.innerText = diffPosZ.toString();
  }

  private toRadians(angle: number): number {
    return angle * (Math.PI / 180);
  }
}
