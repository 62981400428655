import Chart from "chart.js/auto";

export const renderDetectionChart = controller => {
  const colors = ["#00A0E9", "#E60012", "#FFF100", "#009944", "#E5006A", "#EB6100", "#601986", "#CFDB00", "#01B57E", "#0086D1", "#E5004F",
    "#EB6100", "#8FC31F", "#096F6A", "#920783", "#0068B7", "#BE0081", "#984356", "#FCC800", "#22AC38", "#058BA7", "#00479D", "#E4007F", "#F5A7B8"];

  // controllerに定義が必要な変数
  // this.dataValue
  // this.type
  // this.locatorUrlTemplateValue
  // this.assetUrlTemplateValue

  Object.assign(controller, {
    buildLabels() {
      this.labels = [];
      this.dataValue.forEach((record) => {
        if (this.type === "asset") {
          this.labels.push(record.tenant_locator.management_code);
        } else if (this.type === "locator") {
          this.labels.push(record.tenant_asset.management_code);
        }
      });
    },

    buildDatasets() {
      this.datasets = [];
      let data = [];

      if (this.dataValue.length == 0) return;

      this.dataValue.forEach((record) => {
        data.push(record.detection_count);
      });

      this.datasets.push({
        label: "検知回数",
        data: data,
        borderColor: colors,
        backgroundColor: colors.map((color) => {
          return this.hexToRGB(color, 0.3);
        }),
      });
    },

    buildChart() {
      this.chart = new Chart(this.chartTarget, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          indexAxis: "y",
          datasets: {
            bar: {
              borderWidth: 1,
            }
          },
          plugins: {
            tooltip: {
              position: "average",
              xAlign: "left",
              yAlign: "bottom",
            },
            legend: {
              display: false,
            }
          },
        },
      });
    },

    hexToRGB(hex, alpha): string {
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    },

    addClickEvents() {
      this.chartTarget.onclick = (e) => {
        const elements = this.chart.getElementsAtEventForMode(e, "point", { intersect: false }, false);
        if (elements.length > 0) {
          const i = elements[0].index;
          if (this.type == "asset") {
            const code = this.dataValue[i].tenant_locator.code;
            const href = this.locatorUrlTemplateValue.replace(':code', code)
            window.location.href = href;
          } else if (this.type == "locator") {
            const code = this.dataValue[i].tenant_asset.code;
            const href = this.assetUrlTemplateValue.replace(':code', code)
            window.location.href = href;
          }
        }
      }
    },
  });
}
