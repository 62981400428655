import { Controller } from "@hotwired/stimulus";
import { getTrackingStatus, setTrackingStatus, applyTrackingStatusStyle } from "../modules/tracking_status";

export default class extends Controller {
  static targets = ["asset", "trackingStatus"];

  declare trackingStatusTarget: HTMLInputElement;
  declare assetTargets: Array<SVGElement>;

  connect() {
    setTrackingStatus(this.trackingStatus)
  }

  get trackingStatus() {
    return this.trackingStatusTarget.checked ? 'tracking' : 'all';
  }

  changeTrackingStatus(event) {
    setTrackingStatus(this.trackingStatus)
    this.assetTargets.forEach((element) => {
      applyTrackingStatusStyle(element, this.trackingStatus)
    });
  }
}
