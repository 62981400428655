import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];
  static classes = ["checked"];
  static values = {
    container: { type: String, default: ".checkbox-container" },
  };

  declare inputTargets: HTMLInputElement[];
  declare checkedClass: string;
  declare containerValue: string;

  initialize() {
    this.refresh = this.refresh.bind(this)
  }

  connect() {
    this.inputTargets.forEach((input) => input.addEventListener('input', this.refresh))
    this.inputTargets.forEach(input => this.highlight(input))
  }

  disconnect() {
    this.inputTargets.forEach(input => input.removeEventListener('input', this.refresh))
  }

  refresh(e: Event) {
    e.preventDefault();
    this.highlight(e.target as HTMLInputElement);
  }

  highlight(input: HTMLInputElement) {
    if (input.checked) {
      input.closest(this.containerValue).classList.add(this.checkedClass);
    } else {
      input.closest(this.containerValue).classList.remove(this.checkedClass);
    }
  }
}
