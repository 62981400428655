import { createGroup, createElementSvg, nanToZero } from './create_svg'

export const renderAgentMap = controller => {
  Object.assign(controller, {
    replaceAgentMap() {
      const agentMap = this.svg.getElementById(this.agentMapId);
      if (agentMap) {
        agentMap.parentNode.removeChild(agentMap);
      }
      this.svg.appendChild(this.createAgentMap());
    },

    createAgentMap() {
      const g = createGroup(this.agentMapId);
      let prevAgents = {}
      for (const agent of this.agentTargets) {
        try {
          const {x, y, agentCode, at, startsAt, endsAt} = agent.dataset;
          const prevAgent = prevAgents[agentCode];
          g.appendChild(this.createAgentMaker(x, y, agentCode, at, startsAt, endsAt))
          if (prevAgent) {
            g.appendChild(this.createAgentToAgentLine(prevAgent, agent));
          }
          prevAgents[agentCode] = agent;
        } catch (e) {
          console.log(e);
        }
      }
      return g;
    },

    createAgentMaker(x, y, agentCode, at, startsAt, endsAt) {
      const marker = createElementSvg("circle");
      marker.setAttribute("cx", nanToZero(x));
      marker.setAttribute("cy", nanToZero(y));
      marker.setAttribute("r", 0.2);
      marker.setAttribute("stroke", "black");
      marker.setAttribute("stroke-width", 0.01);
      marker.setAttribute("fill", "blue");
      marker.dataset.agent = "agent";
      marker.dataset.agentCode = agentCode;
      marker.dataset.startsAt = startsAt;
      marker.dataset.endsAt = endsAt;
      marker.dataset.at = at;
      return marker;
    },

    createAgentToAgentLine(from, to) {
      const line = createElementSvg("line");
      line.setAttribute("x1", nanToZero(from.dataset.x));
      line.setAttribute("y1", nanToZero(from.dataset.y));
      line.setAttribute("x2", nanToZero(to.dataset.x));
      line.setAttribute("y2", nanToZero(to.dataset.y));
      line.setAttribute("stroke", "black");
      line.setAttribute("stroke-width", 0.01);
      return line;
    },

    get agentMapId() {
      return "__agents__";
    }
  })
}
