import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    options: Object,
  }

  declare optionsValue: {[key:string]: any};

  connect() {
    this.install();

    document.addEventListener('turbo:before-cache', this.uninstall);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.uninstall);
  }

  install() {
    if ("dropdownParent" in this.optionsValue) {
      this.optionsValue.dropdownParent = $(this.optionsValue["dropdownParent"]);
    }
    $(this.element).select2({
      theme: 'bootstrap4',
      language: "ja",
      ...this.optionsValue,
    })
    .on("select2:close", (e) => {
      this.element.dispatchEvent(new Event("change"));
    });
  }
  uninstall = () => {
    $(this.element).select2("destroy");
  }
}
// 対象
// <select data-controller="select2" data-select2-options-value="{'allowClear': true, 'placeholder': ''}">
//   <option value="1" data-href="/dashboard/groups/1">グループ1</option>
//   <option value="2" data-href="/dashboard/groups/2">グループ1</option>
//   <option value="3" data-href="/dashboard/groups/3">グループ1</option>
// </select>
