import { createGroup, createPositionMaker} from './create_svg'
import { getAssetMakerSize } from "../modules/marker_size";
import { getTrackingStatus, applyTrackingStatusStyle } from "../modules/tracking_status";

export const renderAssetMap = controller => {
  Object.assign(controller, {
    replaceAssetMap() {
      const assetMap = this.svg.getElementById(this.assetMapId);
      if (assetMap) {
        this.assetsCircles().forEach(circle => $(circle).tooltip("hide"));
        assetMap.parentNode.removeChild(assetMap);
      }
      this.svg.appendChild(this.createAssetMap());
    },

    assetsCircles(): Array<Element> {
      return Array.from(document.getElementById(this.assetMapId).children);
    },

    createAssetMap() {
      const g = createGroup(this.assetMapId);
      const filterTrackingStatus = getTrackingStatus();
      for (const asset of this.assetTargets) {
        try {
          const size = getAssetMakerSize();
          const {x, y, z, assetCode, managementCode, color, at, trackingStatus} = asset.dataset;
          const circle = createPositionMaker(x, y, assetCode, color, size, trackingStatus);
          const alignX = Math.floor((+x*1000))/1000;
          const alignY = Math.floor((+y*1000))/1000;
          const alignZ = Math.floor((+z*1000))/1000;
          circle.setAttribute("data-marker-size-target", "asset");
          circle.setAttribute("data-asset-tracking-status-target", "asset");
          applyTrackingStatusStyle(circle, filterTrackingStatus);
          $(circle).tooltip({
            title: assetTooltipTemplate(assetCode, managementCode, alignX, alignY, alignZ, at),
            html: true,
            customClass: "asset-position",
          });

          g.appendChild(circle)
        } catch (e) {
          console.log(e);
        }
      }
      return g;
    },

    get assetMapId() {
      return "__assets__";
    }
  })
}

export function assetTooltipTemplate(assetCode, managementCode, alignX, alignY, alignZ, time) {
  return (
    [
      `<span class="font-weight-bold border-bottom">${assetCode}</span>`,
      `<span class="border-bottom">${managementCode}</span>`,
      `(${alignX}, ${alignY}, ${alignZ})`,
      `${time}`,
    ].join('<br>')
  )
}
