import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output" ];

  declare outputTarget: HTMLInputElement;

  copy(e: any) {
    e.preventDefault();
    this.outputTarget.value = e.target.value;
    this.outputTarget.dispatchEvent(new Event('change'));
  }
}

// <div data-controller="copy-from">
//   <input type="text" data-copy-form-target="output">
//   <button value="aaa" data-action="copy-from#copy">copy</button>
// </div>
