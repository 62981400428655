import { Controller } from "@hotwired/stimulus"
import { renderLocatorMap } from "../../mixins/render_locator_map"
import { transformPoint, createGroup, createLocatorMaker, createMapMarker, createElementSvg } from '../../mixins/create_svg'
import { LocatorPosition } from "../../modules/locator_position";

export default class extends Controller {
  static targets = ["locator", "locatorSelect", "locatorPositionForm", "isRefPos", "inputPosX", "inputPosY",
    "inputPosZ", "posX", "posY", "posZ", "refPosX", "refPosY", "refPosZ", "diffPosX", "diffPosY", "diffPosZ"];

  static values = { code: String, action: String, locatorPositions: Array };

  declare codeValue: string;
  declare actionValue: string;
  declare locatorPositionsValue: Array<Object>
  declare locatorTargets: Array<HTMLElement>
  declare locatorPositionFormTarget: HTMLElement
  declare locatorSelectTarget: HTMLSelectElement
  declare isRefPosTarget: HTMLInputElement
  declare inputPosXTarget: HTMLInputElement
  declare inputPosYTarget: HTMLInputElement
  declare inputPosZTarget: HTMLInputElement
  declare posXTarget: HTMLInputElement
  declare posYTarget: HTMLInputElement
  declare posZTarget: HTMLInputElement
  declare diffPosXTarget: HTMLInputElement
  declare diffPosYTarget: HTMLInputElement
  declare diffPosZTarget: HTMLInputElement
  declare refPosXTarget: HTMLInputElement
  declare refPosYTarget: HTMLInputElement
  declare refPosZTarget: HTMLInputElement

  replaceLocatorMap: Function
  offsetX: number
  offsetY: number
  targetLocator: LocatorPosition
  selectedLocator: LocatorPosition
  selectedIndex: number
  marker: HTMLElement

  initialize() {
    renderLocatorMap(this);
  }

  render() {
    if (this.svg) {
      this.replaceLocatorMap();
    }
  }

  setAction() {
    if (this.actionValue === "index") {
      return
    }

    if (this.actionValue === "new") {
      this.svg.addEventListener("mouseup", event => this.clickPosition(event));
      if (this.inputPosXTarget.value && this.inputPosYTarget.value) {
        const { x, y } = this.calcPositionXY();
        const point = this.svg.createSVGPoint();
        point.x = x;
        point.y = y;
        this.setPoint(point);
      }
    }

    if (this.actionValue === "edit") {
      this.locatorElm.childNodes.forEach((node) => {
        const elm = node as HTMLElement;
        if (elm.dataset.code === this.codeValue) {
          this.convertPosition(elm, elm.dataset.code);
          elm.parentNode.removeChild(elm);
        }
      });
    }

    this.svg.addEventListener("mouseup", event => this.endDrag(event));
    this.svg.addEventListener("mousemove", event => this.drag(event));
  }

  convertPosition(elm, code) {
    const { x, y } = this.calcPositionXY();
    const point = this.svg.createSVGPoint();
    point.x = x;
    point.y = y;
    const pointMarker = this.setPoint(point, code);
    pointMarker.addEventListener("mousedown", event => this.startDrag(event));
  }

  clickPosition(event) {
    if (!this.selectedLocator) {
      event.preventDefault();
      const point = this.transformClientXY(event);
      this.selectedIndex = 0;
      const g = this.setPoint(point);
      this.selectedLocator = this.targetLocator;
      g.addEventListener("mousedown", event => this.startDrag(event));
      this.setInputPosition(point.x, point.y);

      const inputPosZ = Number(this.inputPosZTarget.value);
      this.setPosition(point.x, point.y, inputPosZ);
      if (this.isRefPosTarget.checked) this.setReferencePosition(point.x, point.y, inputPosZ);
    }
  }

  drag(event) {
    if (this.selectedLocator) {
      event.preventDefault();
      const point = this.transformClientXY(event);
      const { x, y, inputX, inputY } = this.calcDragPosition(point);
      this.selectedLocator.x = x;
      this.selectedLocator.y = y;
      this.setInputPosition(inputX, inputY);
      this.setOffsetXY(point);

      const inputPosZ = Number(this.inputPosZTarget.value);
      this.setPosition(inputX, inputY, inputPosZ);
      if (this.isRefPosTarget.checked) this.setReferencePosition(inputX, inputY, inputPosZ);
    }
  }

  endDrag(event) {
    this.selectedLocator = null;
  }

  startDrag(event) {
    event.preventDefault();
    const point = this.transformClientXY(event);
    this.setOffsetXY(point);
    this.selectedLocator = new LocatorPosition(event.target);
  }

  inputPosition(elm) {
    const { x, y } = this.getInputPositionXY();
    if (this.targetLocator) {
      this.targetLocator.x = x;
      this.targetLocator.y = y;
    } else {
      const targetIdx = Number(elm.target.dataset.index);
      const locatorCode = this.getLocatorCodeFromSelectBox(this.locatorSelectTarget);
      const point = transformPoint(x, y, this.svg);
      this.setPoint(point, locatorCode);
    }

    // adminのロケーター設置ではアンテナ中心点での座標入力のみなのでSkip
    if (this.targets.has("isRefPos")) this.calcAntennaCenter();
  }

  changeCircleCode(elm) {
    const locatorCode = this.getLocatorCodeFromSelectBox(elm.target);
    const targetIdx = Number(elm.target.dataset.index);
    const targetLocator = this.locatorElm.childNodes[targetIdx] as HTMLElement;
    if (targetLocator) {
      targetLocator.dataset.code = locatorCode;
    }
  }

  setPoint(point, code = "") {
    const locatorPositionGroup = this.svg.getElementById(this.locatorPositionId);
    if (locatorPositionGroup) {
      locatorPositionGroup.parentNode.removeChild(locatorPositionGroup);
    }

    const g = createGroup(this.locatorPositionId);
    const x = LocatorPosition.parseFloatFixed(point.x);
    const y = LocatorPosition.parseFloatFixed(point.y);
    //TODO: 座標点がマーカー分ずれる
    // const map = createMapMarker(x, y, code);
    this.targetLocator = LocatorPosition.create(x, y, code);
    g.appendChild(this.targetLocator.rect);

    this.svg.appendChild(g);
    this.targetLocator.rect.addEventListener("mousedown", event => this.startDrag(event));
    this.targetLocator.rect.style.cursor = "pointer";
    return g;
  }

  setInputPosition(x, y) {
    this.inputPosXTarget.value = String(LocatorPosition.parseFloatFixed(String(x)));
    this.inputPosYTarget.value = String(LocatorPosition.parseFloatFixed(String(y)));
  }

  setReferencePosition(x, y, z) {
    this.refPosXTarget.value = String(LocatorPosition.parseFloatFixed(String(x)));
    this.refPosYTarget.value = String(LocatorPosition.parseFloatFixed(String(y)));
    this.refPosZTarget.value = String(LocatorPosition.parseFloatFixed(String(z)));
  }

  setPosition(x, y, z) {
    this.posXTarget.value = String(LocatorPosition.parseFloatFixed(Number(x) + Number(this.diffPosXTarget.value)));
    this.posYTarget.value = String(LocatorPosition.parseFloatFixed(Number(y) + Number(this.diffPosYTarget.value)));
    this.posZTarget.value = String(LocatorPosition.parseFloatFixed(Number(z) + Number(this.diffPosZTarget.value)));
  }

  getInputPositionXY() {
    const { x, y } = this.calcPositionXY();
    return {
      x: x - LocatorPosition.half_size(),
      y: y - LocatorPosition.half_size()
    };
  }

  getLocatorCodeFromSelectBox(selectbox): string {
    return selectbox.selectedOptions[0].text.match(/^\w*/)[0];
  }

  calcDragPosition(point) {
    const x = this.selectedLocator.x + point.x - this.offsetX;
    const y = this.selectedLocator.y + point.y - this.offsetY;
    const inputX = x + LocatorPosition.half_size();
    const inputY = y + LocatorPosition.half_size();
    return { x, y, inputX, inputY };
  }

  calcPositionXY() {
    const x = Number(this.inputPosXTarget.value);
    const y = Number(this.inputPosYTarget.value);
    return { x, y };
  }

  calcAntennaCenter() {
    const inputPosX = Number(this.inputPosXTarget.value);
    const inputPosY = Number(this.inputPosYTarget.value);
    const inputPosZ = Number(this.inputPosZTarget.value);
    this.setPosition(inputPosX, inputPosY, inputPosZ);
    if (this.isRefPosTarget.checked) this.setReferencePosition(inputPosX, inputPosY, inputPosZ);
  }

  transformClientXY(event) {
    const x = LocatorPosition.parseFloatFixed(event.clientX);
    const y = LocatorPosition.parseFloatFixed(event.clientY);
    return transformPoint(x, y, this.svg);
  }

  setOffsetXY(point) {
    this.offsetX = point.x;
    this.offsetY = point.y;
  }

  get svg(): SVGSVGElement {
    return this.element.getElementsByClassName("svg-map")[0].getElementsByTagName("svg")[0];
  }

  get locatorElm() {
    return this.svg.getElementById("__locators__");
  }

  get locatorPositionId() {
    return "__locator_position__";
  }
}
