import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

  connect() {
    this.install();

    document.addEventListener('turbo:before-cache', this.uninstall);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.uninstall);
  }

  close(event) {
    if (this.hasClose(event)) {
      this.uninstall();
    }
  }

  install() {
    if (this.element) {
      $(this.element).modal("show");
      $(this.element).on("hidden.bs.modal", (event) => {
        this.element.remove(); // turboのcacheが残る時があるのでhide時にmodal要素自体削除
      })
    }
  }

  uninstall() {
    if (this.element) {
      $(this.element).modal("hide");
    }
  }

  private hasClose(event): boolean {
    if (event.detail && event.detail.success) {
      return true;
    }

    console.log(event.params);
    if (event.params && event.params.close) {
      return true;
    }

    return false;
  }
}
