import { Controller } from "@hotwired/stimulus"
import { get as originalGet} from '@rails/request.js'
import JSZip from "jszip";
import pLimit from 'p-limit';

const get = async (url, options) => {
  const {maxRetry: maxRetry = 3} = options;
  let retryCount = 0;
  while(true) {
    try {
      return await originalGet(url, options)
    } catch (error) {
      if (retryCount < maxRetry) {
        console.log(error)
        retryCount++;
      } else {
        throw error
      }
    }
  }
}

export default class ExportSettingController extends Controller {
  declare urlValue: String;
  declare locatorsUrlValue: String;
  declare assetsUrlValue: String;
  declare floorsUrlValue: String;
  declare floorAreasUrlTemplateValue: String;
  declare floorEstimationScopesUrlTemplateValue: String;
  declare replaceKeyFloorIdValue: string;

  static values = {
    url: String,
    locatorsUrl: String,
    assetsUrl: String,
    floorsUrl: String,
    floorAreasUrlTemplate: String,
    floorEstimationScopesUrlTemplate: String,
    replaceKeyFloorId: String,
  }

  connect() {
  }

  disconnect() {
  }

  async downloadZip(e) {
    e.target.disabled = true;
    try {
      const result = await this.buildZip();
      const uri = URL.createObjectURL(result)
      const link = document.createElement('a')
      link.download = 'export-setting.zip'
      link.href = uri
      link.click()
    } catch(e) {
      window.alert(e);
    } finally {
      e.target.disabled = false;
    }
  }

  async fetchData() {
    const {locators} = await this.fetchLocators();
    const {assets} = await this.fetchAssets();
    const {floors} = await this.fetchFloors();

    const apiLimit = pLimit(5);

    await Promise.all(floors.map(async (floor) => {
      return apiLimit(async () => {
        const {areas} = await this.fetchFloorAreas(floor["id"])
        floor["areas"] = areas;

        const {estimation_scopes} = await this.fetchFloorEstimationScopes(floor["id"])
        floor["estimation_scopes"] = estimation_scopes;
        return floor;
      })
    }))

    return {locators, assets, floors};
  }

  async buildZip() {
    const {locators, assets, floors} = await this.fetchData();
    const zip = new JSZip()
    zip.file("locator.json", JSON.stringify({locators}))
    zip.file("asset.json", JSON.stringify({assets}))
    zip.file("floors.json", JSON.stringify({floors}))
    return await zip.generateAsync({type: 'blob'})
  }

  async fetchLocators() {
    const response = await get(this.locatorsUrlValue, { cache: "no-cache" });
    return response.json;
  }

  async fetchAssets() {
    const response = await get(this.assetsUrlValue, { cache: "no-cache" });
    return response.json;
  }

  async fetchFloors() {
    const response = await get(this.floorsUrlValue, { cache: "no-cache" });
    return response.json;
  }

  async fetchFloorAreas(floorId) {
    const url = this.floorAreasUrlTemplateValue.replace(this.replaceKeyFloorIdValue, floorId)
    const response = await get(url, { cache: "no-cache", maxRetry: 3 });
    return response.json;
  }

  async fetchFloorEstimationScopes(floorId) {
    const url = this.floorEstimationScopesUrlTemplateValue.replace(this.replaceKeyFloorIdValue, floorId)
    const response = await get(url, { cache: "no-cache", maxRetry: 3 });
    return response.json;
  }

}
