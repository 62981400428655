import { Controller } from "@hotwired/stimulus"
import { EventSourcePolyfill } from "event-source-polyfill";

export default class EventStreamController extends Controller {
  eventSource: EventSourcePolyfill;
  declare apiKeyTarget: HTMLInputElement
  declare hasApiKeyTarget: Boolean
  declare outputTarget: HTMLElement
  declare urlValue: String;

  static targets = [ "apiKey", "output" ];
  static values = { url: String }

  connect() {
    if (this.hasApiKeyTarget) {
      this.setEventSource();
      this.openListener();
      this.messageListener();
    }
  }

  disconnect() {
    this.closeListener();
  }

  setEventSource() {
    const headers = this.hasApiKeyTarget ? { "Authorization": "Bearer " + this.getApiKey() } : {};

    this.eventSource = new EventSourcePolyfill(this.urlValue, {
      headers: headers
    });
  }

  openListener() {
    this.eventSource.addEventListener("open", (event: any) => {
      console.log(event.type);
    });
  }

  messageListener() {
    this.eventSource.addEventListener("message", (message: any) => {
      console.log(message.type);
      const messageContent = document.createElement("p");
      messageContent.textContent = message.data;
      this.outputTarget.appendChild(messageContent);
    });
  }

  closeListener() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  stream() {
    if (!this.eventSource || this.eventSource.readyState === EventSourcePolyfill.CLOSED) {
      this.setEventSource();
      this.openListener();
    }
    this.messageListener();
  }

  getApiKey(): string {
    return this.hasApiKeyTarget ? this.apiKeyTarget.value : "";
  }
}
