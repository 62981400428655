import { createGroup, createElementSvg, nanToZero } from './create_svg'

export const renderPersonDensity = controller => {
  Object.assign(controller, {
    replacePersonDensityMap() {
      const personDensityMap = this.svg.getElementById(this.personDensityMapId);
      if (personDensityMap) {
        personDensityMap.parentNode.removeChild(personDensityMap);
      }
      this.svg.insertAdjacentElement('afterbegin', this.createPersonDensityMap());
    },

    createPersonDensityMap() {
      const g = createGroup(this.personDensityMapId);
      if (this.hasPersonDensityMapTarget) {
        const {content} = this.personDensityMapTarget.dataset;
        const {value_field} = JSON.parse(content);
        for (const data of value_field) {
          const {position, value} = data;
          g.appendChild(this.createPersonDensityArea(position, value))
        }
      }
      return g;
    },

    createPersonDensityArea(position, value) {
      const area = createElementSvg("rect");
      area.setAttribute("x", nanToZero(position.x));
      area.setAttribute("y", nanToZero(position.y));
      area.setAttribute("width", nanToZero(position.w));
      area.setAttribute("height", nanToZero(position.h));
      area.setAttribute("fill", "red");
      area.setAttribute("fill-opacity", value);
      return area;
    },

    get personDensityMapId() {
      return "__person_density__";
    }
  })
}
