import { createPositionMaker } from "../../mixins/create_svg";

export class Circle {
  circle: SVGCircleElement

  static create(x, y, color) {
    return new Circle(createPositionMaker(x, y, "", color));
  }

  static parseFloatFixed(num) {
    return Number.parseFloat(num).toFixed(3);
  }

  constructor(circle: SVGCircleElement) {
    this.circle = circle;
  }

  get x(): number {
    return Number(this.circle.getAttribute("cx"));
  }

  get y(): number {
    return Number(this.circle.getAttribute("cy"));
  }

  set x(value: number) {
    this.circle.setAttribute("cx", Circle.parseFloatFixed(value));
  }

  set y(value: number) {
    this.circle.setAttribute("cy", Circle.parseFloatFixed(value));
  }
}
