import { getLocatorMakerSize } from "../modules/marker_size";
import { createGroup, createLocatorMaker, createPositionMaker} from './create_svg'

export const renderLocatorMap = controller => {
  Object.assign(controller, {
    replaceLocatorMap() {
      const locatorMap = this.svg.getElementById(this.locatorMapId);
      if (locatorMap) {
        locatorMap.parentNode.removeChild(locatorMap);
      }
      this.svg.appendChild(this.createLocatorMap());
    },

    createLocatorMap() {
      const g = createGroup(this.locatorMapId);
      if (this.locatorPositionsValue && this.locatorPositionsValue.length > 0) {
        for (const locatorPosition of this.locatorPositionsValue) {
          try {
            const {pos_x, pos_y, locator_code, color, peers_items, disabled_at} = locatorPosition;
            const size = getLocatorMakerSize();
            const rect = createLocatorMaker(pos_x, pos_y, locator_code, color, size);
            const popoverTitleSelector = "#locator-position-popover-" + locator_code;
            rect.setAttribute("data-marker-size-target", "locator");

            if (peers_items) {
              const peerLocatorCodes = peers_items.map((peer) =>  peer.locator_code);
              $(rect).on("mouseover click", (e) => {
                document.getElementById(this.locatorMapId).querySelectorAll("rect").forEach((elm) => {
                  if (peerLocatorCodes.includes(elm.dataset.code)) {
                    const peer = peers_items.find(item => item.locator_code == elm.dataset.code);
                    elm.classList.add(peer.css_class);
                  }
                });
              });

              $(rect).on("mouseleave", (e) => {
                document.getElementById(this.locatorMapId).querySelectorAll("rect").forEach((elm) => {
                  if (peerLocatorCodes.includes(elm.dataset.code)) {
                    const peer = peers_items.find(item => item.locator_code == elm.dataset.code);
                    elm.classList.remove(peer.css_class);
                  }
                });
              });
            }

            if (disabled_at) {
              rect.classList.add("locator-disabled");
            }

            if ($(popoverTitleSelector).html()) {
              $(rect).tooltip({
                container: 'body',
                title: $(popoverTitleSelector).html(),
                html: true,
                customClass: "locator-position",
                sanitize: false,
                trigger: "click focus",
              });
              // tooltip表示時に他のtooltipを隠す
              $(rect).on("show.bs.tooltip", () => {
                // 全てのtooltipを隠してしまう強い指定だが、
                // このコードを実行するときには他のtooltipは隠れている
                // （マウスカーソルが移動している）ため、
                // 実用上は問題ない認識
                $('.tooltip').not(this).tooltip("hide");
              });
              // tooltip内部の閉じるボタンでtooltipを非表示にする
              $(rect).on("shown.bs.tooltip", () => {
                $(".tooltip-inner [data-dismiss=tooltip]").on("click", function(e){
                    $(rect).tooltip("hide");
                });
              });
            }
            g.appendChild(rect);
          } catch (e) {
            console.log(e);
          }
        }
      }
      return g;
    },

    get locatorMapId() {
      return "__locators__";
    }
  })
}
