import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  assetsCircles(): Array<Element> {
    return Array.from(document.getElementById(this.assetMapId).children);
  }

  visible(event) {
    const assetCode = event.target.getAttribute("data-code");
    const circle = this.assetsCircles().find(circle => circle.getAttribute("data-code") == assetCode);
    if (event.target.checked) {
      circle.classList.add("d-block");
      circle.classList.remove("d-none");
    } else {
      circle.classList.add("d-none");
      circle.classList.remove("d-block");
    }
  }

  get assetMapId() {
    return "__assets__";
  }
}
