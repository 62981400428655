import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static values = { fixed: Number };

  declare fixedValue: number;

  floatFixed(event) {
    const val = event.target.value.replace(/[^\d-.]/g,'');
    event.target.value = Number.parseFloat(val).toFixed(this.fixedValue);
  }
}
