import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  refreshTimer!: ReturnType<typeof setTimeout>;

  connect() {
    this.load()

    if (this.data.has("refreshInterval")) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html;
        this.element.dispatchEvent(new Event("change"));
    })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, +this.data.get("refreshInterval"));
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
