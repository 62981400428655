import { Controller } from "@hotwired/stimulus"
import { renderAgentMap } from "../../mixins/render_agent_map"
import { renderAreaRegion } from "../../mixins/render_area_region"
import { renderAssetMap } from "../../mixins/render_asset_map"
import { renderLocatorMap } from "../../mixins/render_locator_map"
import { renderMarkerMap } from "mixins/render_marker_map"
import { renderPersonDensity } from "../../mixins/render_person_density"

export default class FloorMapController extends Controller {
  static values = { selector: String, locatorPositions: Array, areaRegions: Array };
  static targets = ["asset", "marker", "agent", "personDensityMap"];

  declare selectorValue: string
  declare locatorPositions: Array<Object>
  declare areaRegionsValue: Array<Object>
  declare assetTargets: Array<HTMLElement>
  declare markerTargets: Array<HTMLElement>
  declare agentTargets: Array<HTMLElement>
  declare personDensityMapTarget: HTMLElement
  declare hasPersonDensityMapTarget: boolean
  replaceAgentMap: Function
  replaceAreaRegion: Function
  replaceAssetMap: Function
  replaceLocatorMap: Function
  replaceMarkerMap: Function
  replacePersonDensityMap: Function

  initialize() {
    renderAgentMap(this);
    renderAreaRegion(this);
    renderAssetMap(this);
    renderLocatorMap(this);
    renderMarkerMap(this);
    renderPersonDensity(this);
  }

  render() {
    if (this.svg) {
      this.replaceLocatorMap();
      this.replaceAssetMap();
      this.replaceAgentMap();
      this.replacePersonDensityMap();
      this.replaceAreaRegion();
      this.replaceMarkerMap();
    }
  }

  get svg() {
    return this.element.querySelector(this.selectorValue || 'svg');
  }
}
