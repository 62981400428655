import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  changeFileName(event) {
    const fileName = event.target.value.replace(/^.*[\\\/]/, "");
    const customFileInput = event.target.parentNode as HTMLElement;
    const labelElm = customFileInput.querySelector("label");
    labelElm.textContent = fileName;
  }
}
