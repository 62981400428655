import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";
import { useVisibility } from 'stimulus-use';

export default class extends Controller {
  static values = { url: String, interval: { type: Number, default: 1000 }}
  declare urlValue: string;
  declare intervalValue: number;

  intervalId: number = 0;
  pollingEnabled: boolean = false;
  isVisible: boolean = false

  connect() {
    useVisibility(this);
    this.pollingEnabled = true;
    this.polling();
  }

  disconnect() {
    this.pollingEnabled = false;
  }

  async polling() {
    if (this.pollingEnabled && this.isVisible) {
      this.updateElements();
    }
    await new Promise(resolve => setTimeout(resolve, this.intervalValue));
    await this.polling();
  }

  updateElements() {
    fetch(this.urlValue, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } })
      .then(response => response.text())
      .then(message => Turbo.renderStreamMessage(message))
      .catch ((e) => {
        console.error(e);
        this.pollingEnabled = false
      })
  }
}
