import { Controller } from '@hotwired/stimulus';
import Cookies from "js-cookie";

export default class extends Controller {
  static classes = [ "expanding" ];
  static values = { cookieName: String };

  declare expandingClass: string;
  declare cookieNameValue: string;

  connect() {
    this.applySidebar();
  }

  toggleSidebar() {
    this.toggleState();
    this.applySidebar();
  }

  applySidebar() {
    if (this.isSidebarExpand) {
      this.expandSidebar();
    } else {
      this.collapseSidebar();
    }
  }

  expandSidebar() {
    this.element.classList.add(this.expandingClass)
  }

  collapseSidebar() {
    this.element.classList.remove(this.expandingClass)
  }

  toggleState() {
    if (this.isSidebarExpand) {
      Cookies.set(this.cookieNameValue, "0");
    } else {
      Cookies.set(this.cookieNameValue, "1");
    }
  }


  get isSidebarExpand(): boolean {
    const cookieValue = Cookies.get(this.cookieNameValue);
    return cookieValue ? (cookieValue == "1") : true;
  }
}

/* 対象
<div data-controller="container-with-sidebar">
  <div class="sidebar">
    <div data-action="click->container-with-sidebar#toggleSidebar">
      <div class="btn-expand"/>
      <div class="btn-collapse"/>
    </div>
  </div>
  <div class="page-layout-main">
  </div>
</div>
*/

