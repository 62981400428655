import Cookies from "js-cookie";

export const DEFAULT_LOCATOR_SIZE = 0.2;
export const DEFAULT_ASSET_SIZE = 0.1;
export const DEFAULT_MARKER_SIZE = 0.1;
export const COOKIE_LOCATOR_MARKER_SIZE = "locator_marker_size";
export const COOKIE_ASSET_MARKER_SIZE = "asset_marker_size";
export const COOKIE_MARKER_MARKER_SIZE = "marker_marker_size";
export const COOKIE_MARKER_EXPIRE_DAYS = 365;

export function getLocatorMakerSize() {
  return Number(Cookies.get(COOKIE_LOCATOR_MARKER_SIZE) || DEFAULT_LOCATOR_SIZE)
}

export function setLocatorMakerSize(size) {
  return Cookies.set(COOKIE_LOCATOR_MARKER_SIZE, size, { expires: COOKIE_MARKER_EXPIRE_DAYS });
}

export function getAssetMakerSize() {
  return Number(Cookies.get(COOKIE_ASSET_MARKER_SIZE) || DEFAULT_ASSET_SIZE)
}

export function setAssetMakerSize(size) {
  return Cookies.set(COOKIE_ASSET_MARKER_SIZE, size, { expires: COOKIE_MARKER_EXPIRE_DAYS });
}

export function getMarkerMarkerSize() {
  return Number(Cookies.get(COOKIE_MARKER_MARKER_SIZE) || DEFAULT_MARKER_SIZE)
}

export function setMarkerMakerSize(size) {
  return Cookies.set(COOKIE_MARKER_MARKER_SIZE, size, { expires: COOKIE_MARKER_EXPIRE_DAYS });
}
