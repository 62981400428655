import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const context = require.context(".", true, /\.ts$/);
application.load(definitionsFromContext(context));
application.debug = process.env.NODE_ENV === 'development';

// staging, productionでデバッグモードを有効にするためにApplicationを公開する
window.Stimulus = application;
