import { Controller } from "@hotwired/stimulus"
import { renderDetectionChart } from "../mixins/render_detection_chart"
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["chart"];
  static values = { data: Array, locatorUrlTemplate: String }

  declare chart: Chart;
  declare chartTarget: HTMLCanvasElement;
  declare hasChartTarget: Boolean;
  declare dataValue: Array<any>;
  declare locatorUrlTemplateValue: String;
  declare labels: Array<String>;
  declare datasets: Array<any>;
  buildLabels: Function;
  buildDatasets: Function;
  buildChart: Function;
  addClickEvents: Function;

  readonly type = "asset";

  initialize() {
    renderDetectionChart(this);
  }

  connect() {
    if (this.hasChartTarget) {
      this.buildLabels();
      this.buildDatasets();
      this.buildChart();
      this.addClickEvents();
    }
  }
}
