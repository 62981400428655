import { Controller } from "@hotwired/stimulus"
import PanZoom from "panzoom"

export default class extends Controller {
  static values = { selector: String, options: Object };
  static targets = [ "indicator" ];

  declare selectorValue: string;
  declare optionsValue: Object;
  declare hasIndicatorTarget: boolean;
  declare indicatorTarget: HTMLElement;

  panzoom: ReturnType<typeof PanZoom>;

  connect() {
    this.install();

    document.addEventListener('turbo:before-cache', this.uninstall);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.uninstall);
  }

  install() {
    if (this.target) {
      const centerCenter = {x: 0.5, y: 0.5};
      this.panzoom = PanZoom(this.target, {
        maxZoom: 3,
        minZoom: 0.8,
        initialZoom: 1,
        transformOrigin: centerCenter,
        bounds: true,
        boundsPadding: 0.79, // minZoomの値を超えると拡大ができなくなる
        beforeWheel: function(e) {
          // allow wheel-zoom only if altKey is down. Otherwise - ignore
          const shouldIgnore = !e.altKey;
          return shouldIgnore;
        },
        beforeMouseDown: function(e) {		/* ドラッグによるパンを止める */
          var shouldIgnore = !e.altKey;		/* この行を消せばパンを完全に止める */
          return shouldIgnore;
        },

        ...this.optionsValue,
      })
      this.panzoom.on("transform", this.updateIndicator)
    }
  }

  uninstall() {
    if (this.panzoom) {
      this.panzoom.dispose()
    }
  }

  updateIndicator = (e) => {
    if (this.hasIndicatorTarget) {
      this.indicatorTarget.innerText = this.panzoom.getTransform().scale.toFixed(3)
    }
  }

  reset = () => {
    if (this.panzoom) {
      this.panzoom.moveTo(0, 0);
      this.panzoom.zoomAbs(0, 0, 1);
    }
  }

  get target() {
    return this.element.querySelector(this.selectorValue) as HTMLElement
  }
}
