import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = [ "layout" ];
  static targets: ["status"];
  static values = { cookieName: String };

  declare layoutClass: string;
  declare cookieNameValue: string;
  declare statusTarget: HTMLElement;

  connect() {
    this.applyLayout();
  }

  toggleLayout() {
    this.toggleState();
    this.applyLayout();
  }

  applyLayout() {
    if (this.isTwoColumn) {
      this.applyTwoColumnLayout();
    } else {
      this.applyOneColumnLayout();
    }
  }

  applyTwoColumnLayout() {
    this.element.classList.add(this.layoutClass)
  }

  applyOneColumnLayout() {
    this.element.classList.remove(this.layoutClass)
  }

  toggleState() {
    if (this.isTwoColumn) {
      document.cookie = `${this.cookieNameValue}=; max-age=0`;
    } else {
      document.cookie = `${this.cookieNameValue}=1`;
    }
  }

  get isTwoColumn(): boolean {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${this.cookieNameValue}=1`));
  }
}

