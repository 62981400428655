import { Controller } from "@hotwired/stimulus";
import { LocatorPosition } from "../modules/locator_position";
import { getLocatorMakerSize, getAssetMakerSize, getMarkerMarkerSize, setLocatorMakerSize, setAssetMakerSize, setMarkerMakerSize } from "../modules/marker_size";

export default class extends Controller {
  static targets = ["locator", "asset", "marker", "locatorSize", "assetSize", "markerSize"];

  declare locatorSizeTarget: HTMLInputElement;
  declare assetSizeTarget: HTMLInputElement;
  declare markerSizeTarget: HTMLInputElement;
  declare hasLocatorSizeTarget: Boolean;
  declare hasAssetSizeTarget: Boolean;
  declare hasMarkerSizeTarget: Boolean;
  declare locatorTargets: Array<SVGElement>;
  declare assetTargets: Array<SVGElement>;
  declare markerTargets: Array<SVGElement>;

  connect() {
    if (this.hasLocatorSizeTarget) {
      this.locatorSizeTarget.value = String(getLocatorMakerSize());
    }

    if (this.hasAssetSizeTarget) {
      this.assetSizeTarget.value = String(getAssetMakerSize());
    }

    if (this.hasMarkerSizeTarget) {
      this.markerSizeTarget.value = String(getMarkerMarkerSize());
    }
  }

  changeLocatorSize(event) {
    const size = event.target.value;
    setLocatorMakerSize(size);
    this.locatorTargets.forEach((elm) => {
      const x = Number(elm.getAttribute("data-original-x")) - Number(LocatorPosition.half_size());
      const y = Number(elm.getAttribute("data-original-y")) - Number(LocatorPosition.half_size());
      elm.setAttribute("x", String(x));
      elm.setAttribute("y", String(y));
      elm.setAttribute("width", size);
      elm.setAttribute("height", size);
    });
  }

  changeAssetSize(event) {
    const size = event.target.value;
    setAssetMakerSize(size);
    this.assetTargets.forEach((elm) => {
      elm.setAttribute("r", size);
    });
  }

  changeMarkerSize(event) {
    const size = event.target.value;
    setMarkerMakerSize(size);
    this.markerTargets.forEach((elm) => {
      elm.setAttribute("r", size);
    });
  }
}
