import { Controller } from "@hotwired/stimulus";
import { createGroup, createPositionMaker } from '../../mixins/create_svg';
import { getAssetMakerSize } from "../../modules/marker_size";
import { assetTooltipTemplate } from "../../mixins/render_asset_map";
import { getTrackingStatus, applyTrackingStatusStyle } from "../../modules/tracking_status";
import { get } from '@rails/request.js';
import { useVisibility } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "asset", "svgContent", "output", "startButton", "stopButton" ];
  declare outputTarget: HTMLElement
  static values = { url: String, duration: Number };

  pollingEnabled: boolean = false;
  isVisible: boolean = false
  declare svgContentTarget: HTMLElement
  declare assetTargets: Array<HTMLElement>
  declare urlValue: string;
  declare durationValue: number;
  declare startButtonTarget: HTMLElement
  declare stopButtonTarget: HTMLElement

  readonly ASSET_POSITION_LIMIT: number = 5;
  readonly REMOVE_TIME: number = 120;

  connect() {
    useVisibility(this);
    this.pollingEnabled = false;
    this.update();
    this.polling();
  }

  disconnect() {
    this.pollingEnabled = false;
  }

  toggleEvent() {
    this.pollingEnabled = !this.pollingEnabled;
    this.update();
  }

  start() {
    this.pollingEnabled = true;
    this.update();
  }

  stop() {
    this.pollingEnabled = false;
    this.update();
  }

  update() {
    if (this.pollingEnabled) {
      this.startButtonTarget.classList.add("d-none")
      this.stopButtonTarget.classList.remove("d-none")
    } else {
      this.startButtonTarget.classList.remove("d-none")
      this.stopButtonTarget.classList.add("d-none")
    }
  }

  async polling() {
    if (this.pollingEnabled && this.isVisible) {
      const data = await this.fetchRecentPosition();
      if (data && data.hasOwnProperty("asset_positions") && data.asset_positions.length > 0) {
        data.asset_positions.forEach((position) => {
          this.output(position);
        });
        this.adjustAssetPositions();
        this.replaceAssetMap();
      }
    }
    await new Promise(resolve => setTimeout(resolve, this.durationValue || 10000));
    await this.polling();
  }

  async fetchRecentPosition() {
    const response = await get(this.urlValue, { cache: "no-cache" });

    if (response.ok) {
      return response.json;
    } else {
      if (response.statusCode !== 404) {
        console.error(response.statusText);
      }
      return null;
    }
  }

  output(data) {
    const messageContent = document.createElement("div");
    messageContent.setAttribute("data-floors--live-target", "asset");
    messageContent.dataset.x = data.x;
    messageContent.dataset.y = data.y;
    messageContent.dataset.z = data.z;
    messageContent.dataset.assetCode = data.asset_code;
    messageContent.dataset.managementCode = data.management_code;
    messageContent.dataset.trackingStatus = data.tracking_status;
    messageContent.dataset.color = data.color;
    messageContent.dataset.opacity = String(1.0);
    messageContent.dataset.at = data.at;
    messageContent.dataset.millsec = data.at_milliseconds;
    this.outputTarget.appendChild(messageContent);
  }

  adjustAssetPositions() {
    const assetPositionGroup = Array.from(this.outputTarget.children).reduce((a, c) => {
      a[c.getAttribute("data-asset-code")] = a[c.getAttribute("data-asset-code")] || [];
      a[c.getAttribute("data-asset-code")].push(c);
      return a;
    }, Object.create(null));

    Object.entries(assetPositionGroup).forEach(([code, value]) => {
      const elements = value as Array<HTMLElement>;
      if (elements.length >= this.ASSET_POSITION_LIMIT) {
        elements[0].remove();
      }
      if (elements.length >= 2) {
        let opacity = 0.9;
        for (let i = elements.length - 1; i >= 0; i--) {
          elements[i].dataset.opacity = String(opacity);
          opacity -= 0.2;
          const elapsedTime = (Date.now() - Number(elements[i].dataset.millsec))/ 1000;
          if (elapsedTime > this.REMOVE_TIME) {
            elements[i].remove();
          }
        }
      }
    })
  }

  replaceAssetMap() {
    const assetMap = this.svg.getElementById(this.assetMapId);
    if (assetMap) {
      $(assetMap).find("circle").tooltip("hide");
      assetMap.parentNode.removeChild(assetMap);
    }
    this.svg.appendChild(this.createAssetMap());
  }

  createAssetMap() {
    const g = createGroup(this.assetMapId);
    const filterTrackingStatus = getTrackingStatus();
    for (const asset of this.assetTargets) {
      try {
        const size = getAssetMakerSize();
        const {x, y, z, assetCode, managementCode, color, opacity, at, trackingStatus} = asset.dataset;
        const circle = createPositionMaker(x, y, assetCode, color, size, trackingStatus);
        circle.setAttribute("data-marker-size-target", "asset");
        circle.setAttribute("style", `opacity: ${Number(opacity).toFixed(1)};`);
        const alignX = Math.floor((+x*1000))/1000;
        const alignY = Math.floor((+y*1000))/1000;
        const alignZ = Math.floor((+z*1000))/1000;
        applyTrackingStatusStyle(circle, filterTrackingStatus);
        $(circle).tooltip({
          title: assetTooltipTemplate(assetCode, managementCode, alignX, alignY, alignZ, at),
          html: true,
          customClass: "asset-position",
        });
        $(circle).on("mouseleave", function () {$(this).tooltip('hide');} )

        g.appendChild(circle)
      } catch (e) {
        console.error(e);
      }
    }
    return g;
  }

  get assetMapId() {
    return "__assets__";
  }

  get svg() {
    return this.svgContentTarget.getElementsByTagName("svg")[0];
  }
}
