import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import * as moment from "moment";

export default class extends Controller {
  static targets = ["date", "areaHistories", "assets", "areas", "chart", "chartContainer", "usageGuide"];
  static values = { isLabelsHidden: Boolean, urlTemplate: String }

  declare chart: Chart;
  declare dateTarget: HTMLInputElement;
  declare areaHistoriesTarget: HTMLElement;
  declare assetsTarget: HTMLElement;
  declare areasTarget: HTMLElement;
  declare chartTarget: HTMLCanvasElement;
  declare chartContainerTarget: HTMLDivElement;
  declare usageGuideTarget: HTMLDivElement;
  declare isLabelsHiddenValue: boolean;
  declare urlTemplateValue: String;
  declare labels: Array<String>;
  declare areaHistories: Array<any>;
  declare datasets: Array<any>;
  declare areaColors: { [key: string]: string; };

  readonly perAssetHeight = 65;
  readonly surplusHeight = 50;
  // 凡例で使用する色の一覧
  readonly colors = ['#00A0E9', '#E60012', '#FFF100', '#009944', '#E5006A', '#EB6100', '#601986', '#CFDB00', '#01B57E', '#0086D1', '#E5004F',
    '#EB6100', '#8FC31F', '#096F6A', '#920783', '#0068B7', '#BE0081', '#984356', '#FCC800', '#22AC38', '#058BA7', '#00479D', '#E4007F', '#F5A7B8'];

  connect() {
    this.datasets = [];
    this.areaColors = {};
    this.buildLabels();
    this.adjustChartSize();

    this.areaHistories = JSON.parse(this.areaHistoriesTarget.innerText);
    this.buildDatasets();
    this.buildUsageGuides();
    this.buildChart();
    this.addClickEvents();
  }

  private buildChart() {
    this.chart = new Chart(this.chartTarget, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        plugins: {
          tooltip: {
            position: 'average',
            xAlign: 'left',
            yAlign: 'bottom',
            callbacks: {
              label: (context) => {
                return context.dataset.label + ' (' + context.raw[0].format('HH:mm') + '~' + context.raw[1].format('HH:mm') + ')'
              }
            }
          },
          legend: {
            display: false,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          xAxis: {
            grid: {
              drawBorder: true,
              lineWidth: [2, 1, 1, 1],
              borderDash: [5, 5],
            },
            type: 'time',
            min: moment(this.dateTarget.value).startOf('d').unix() * 1000,
            max: moment(this.dateTarget.value).endOf('d').add(1, 's').unix() * 1000,
            ticks: {
              font: {
                size: 11,
                lineHeight: 0.5,
              },
              callback: (_tickValue, index, _ticks) => {
                if (index % 60 == 0) {
                  return index / 60 + '時'
                } else if (index % 15 == 0) {
                  return ''
                }
              }
            },
            stacked: false,
          },
          yAxis: {
            ticks: {
              autoSkip: false,
            },
            stacked: true,
          },
        },
      }
    });
  }

  // chart.jsのデータセットを作成
  private buildDatasets() {
    let areaId = 0;
    let areaIndex = 0;
    let assetAnyId = '';

    this.areaHistories.forEach((areaHistory) => {
      // 直前のエリアと異なるならstackをincrementする
      if (areaId != areaHistory['area_id']) {
        areaId = areaHistory['area_id'];
        areaIndex++;
      }

      // 直前のassetと異なるならstackを0にする
      if (assetAnyId != areaHistory['asset_any_id']) {
        assetAnyId = areaHistory['asset_any_id'];
        areaIndex = 0;
      }

      // エリアの色を設定
      let color = '';
      if (areaHistory['area_name'] === 'unknown') {
        color = 'lightgray';
        this.areaColors[areaHistory['area_name']] = color;
      } else {
        if (this.areaColors[areaHistory['area_name']] == undefined) {
          // 凡例用の色をcolorsから取得
          color = this.colors.shift();
          this.areaColors[areaHistory['area_name']] = color;
        } else {
          color = this.areaColors[areaHistory['area_name']];
        }
      }

      // ex) 全部で4アセットある中で2つめのアセットのデータを作成する場合, dataは以下の形で用意する
      //     [[], [{start_time: '', end_time: ''}], [], []]
      let data = [];
      const assetIndex = this.labels.indexOf(areaHistory['asset_name']);
      for (let i = 0; i < assetIndex; i++) {
        data.push([]);
      }
      data.push([moment.utc(areaHistory['start_time']).zone('+09:00'), moment.utc(areaHistory['end_time']).zone('+09:00')]);
      for (let i = 0; i < this.labels.length - assetIndex - 1; i++) {
        data.push([]);
      }

      this.datasets.push({
        label: this.buildAreaName(areaHistory['area_name']),
        data: data,
        backgroundColor: color,
        stack: areaIndex.toString(),
        areaId: areaHistory['area_id'],
        floorId: areaHistory['floor_id'],
        areaGroupId: areaHistory['area_group_id'],
      });
    });
  }

  private buildLabels() {
    const ary = JSON.parse(this.assetsTarget.innerText);

    if (this.isLabelsHiddenValue) {
      this.labels = ary.map((_) => { return '' });
    } else {
      this.labels = ary;
    }
  }

  // 凡例のhtmlを作成
  // id順 かつ unknown は最後になるように並べる
  private buildUsageGuides() {
    let html = '';
    let hasUnknown = false;
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    Object.keys(this.areaColors).sort(collator.compare).forEach((areaName) => {
      if (areaName === 'unknown') {
        hasUnknown = true;
      } else {
        html += `<div class="item"><div class="colorBox" style="background-color: ${this.areaColors[areaName]};"></div>${this.buildAreaName(areaName)}</div>`;
      }
    });
    if (hasUnknown) {
      html += `<div class="item"><div class="colorBox" style="background-color: lightgray;"></div>unknown</div>`;
    }
    this.usageGuideTarget.innerHTML = html;
  }

  // ${area_id}_${building_name}_${floor_name}_${area_name}
  // => ${building_name} ${floor_name} ${area_name}
  private buildAreaName(areaName: string) {
    if (areaName === 'unknown') return areaName;

    const ary = areaName.split('_');
    ary.shift(); // drop area id
    return ary.join(' ');
  }

  // chartのサイズを調整
  private adjustChartSize() {
    this.chartContainerTarget.style.height = this.perAssetHeight * this.labels.length + this.surplusHeight + 'px';
    this.chartTarget.style.height = this.perAssetHeight * this.labels.length + this.surplusHeight + 'px';
  }

  private addClickEvents() {
    this.chartTarget.onclick = (e) => {
      const elements = this.chart.getElementsAtEventForMode(e, 'point', { intersect: false }, false);
      if (elements.length > 0) {
        const i = elements[0].datasetIndex;
        const areaName = this.datasets[i].label;
        const areaId = this.datasets[i].areaId;
        const floorId = this.datasets[i].floorId;
        const areaGroupId = this.datasets[i].areaGroupId;
        if (areaName != 'unknown') {
          const href = this.urlTemplateValue.replace(':area_group_id', areaGroupId).replace(':floor_id', floorId).replace(':area_id', areaId)
          window.location.href = href;
        }
      }
    }
  }
}
