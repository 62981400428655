import { Controller } from "@hotwired/stimulus"
import { transformPoint, createGroup, createMarkerMarker } from '../../mixins/create_svg'

export default class extends Controller {
  static targets = ["marker", "x", "y"];
  static values = { id: String, selector: String, action: String };

  declare markerTarget: HTMLElement;
  declare xTarget: HTMLInputElement;
  declare yTarget: HTMLInputElement;
  declare idValue: string;
  declare selectorValue: string;
  declare actionValue: string;
  targetMarker: SVGElement;
  selectedMarker: SVGElement;

  setAction() {
    if (this.actionValue == "new") {
      this.svg.addEventListener("mouseup", event => this.clickPosition(event));
      if (this.xTarget.value && this.yTarget.value) {
        const point = this.svg.createSVGPoint();
        point.x = Number(Number.parseFloat(this.xTarget.value).toFixed(3));
        point.y = Number(Number.parseFloat(this.yTarget.value).toFixed(3));
        this.setPoint(point);
      }
    }
    if (this.actionValue == "edit") {
      this.markerElm.childNodes.forEach(node => {
        const elm = node as SVGElement;
        if (elm.dataset.id == this.idValue) {
          this.convertPosition();
          elm.parentNode.removeChild(elm);
        }
      });
    }
    this.svg.addEventListener("mouseup", event => this.endDrag(event));
    this.svg.addEventListener("mousemove", event => this.drag(event));
  }

  clickPosition(event) {
    if (this.selectedMarker) return;

    event.preventDefault();
    const point = this.transformClientXY(event);
    const g = this.setPoint(point);
    this.selectedMarker = this.targetMarker;
    g.addEventListener("mousedown", event => this.startDrag(event));
    this.setInputPosition(point.x, point.y);
  }

  setPoint(point) {
    const markerPositionGroup = this.svg.getElementById("markers");
    if (markerPositionGroup) markerPositionGroup.parentNode.removeChild(markerPositionGroup);

    const g = createGroup("markers");
    const x = Number.parseFloat(point.x).toFixed(3);
    const y = Number.parseFloat(point.y).toFixed(3);
    this.targetMarker = createMarkerMarker(x, y, 0, "", "#20c997", 0.1);
    g.appendChild(this.targetMarker);
    this.svg.appendChild(g);
    this.targetMarker.addEventListener("mousedown", event => this.startDrag(event));
    this.targetMarker.style.cursor = "pointer";
    return g;
  }

  inputPosition() {
    const x = Number.parseFloat(this.xTarget.value).toFixed(3);
    const y = Number.parseFloat(this.yTarget.value).toFixed(3);
    this.targetMarker.setAttribute("cx", x);
    this.targetMarker.setAttribute("cy", y);
  }

  private convertPosition() {
    const point = this.svg.createSVGPoint();
    point.x = Number(this.xTarget.value);
    point.y = Number(this.yTarget.value);
    const pointMarker = this.setPoint(point);
    pointMarker.addEventListener("mousedown", event => this.startDrag(event));
  }

  private drag(event) {
    if (!this.selectedMarker) return;

    event.preventDefault();
    const point = this.transformClientXY(event);
    this.setInputPosition(point.x, point.y);
    this.setPoint(point);
  }

  private startDrag(event) {
    event.preventDefault();
    const point = this.transformClientXY(event);
    this.selectedMarker = this.targetMarker;
    this.selectedMarker.setAttribute("cx", point.x);
    this.selectedMarker.setAttribute("cy", point.y);
  }

  private endDrag(event) {
    this.selectedMarker = null;
  }

  private transformClientXY(event) {
    const x = Number.parseFloat(event.clientX).toFixed(3);
    const y = Number.parseFloat(event.clientY).toFixed(3);
    return transformPoint(x, y, this.svg);
  }

  private setInputPosition(x, y) {
    this.xTarget.value = Number.parseFloat(x).toFixed(3)
    this.yTarget.value = Number.parseFloat(y).toFixed(3)
  }

  get markerElm() {
    return this.svg.getElementById("__markers__");
  }

  get svg(): SVGSVGElement {
    return this.element.querySelector(this.selectorValue || "svg");
  }
}
